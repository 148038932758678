import React, { useState, createContext, useEffect } from "react";

export const UserContext = createContext({
  auth: {
    token: null,
    name: null,
    userId: null,
    type: null,
    loaded: null,
    login: () => {},
    logout: () => {},
  },
});

export const UserProvider = ({ children }) => {
  const login = (authData, rememberMe) => {
    setAuth({
      ...auth,
      ...authData,
      loaded: true,
    });

    if (rememberMe) {
      localStorage.setItem("auth", JSON.stringify({ ...authData }));
    } else {
      sessionStorage.setItem("auth", JSON.stringify({ ...authData }));
    }
  };

  const logout = () => {
    setAuth({
      ...auth,
      token: null,
      name: null,
      userId: null,
      type: null,
      loaded: true,
    });

    sessionStorage.clear();
    localStorage.clear();
  };

  const [auth, setAuth] = useState({
    token: null,
    name: null,
    userId: null,
    type: null,
    loaded: false,
    login,
    logout,
  });

  useEffect(() => {
    const existingItem =
      JSON.parse(sessionStorage.getItem("auth")) ||
      JSON.parse(localStorage.getItem("auth"));

    if (existingItem) {
      setAuth((auth) => ({
        ...auth,
        ...existingItem,
        loaded: true,
      }));
    } else {
      setAuth((auth) => ({
        ...auth,
        loaded: true,
      }));
    }
  }, []);

  return (
    <UserContext.Provider value={{ auth }}>{children}</UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export const withUser = (Component) => (props) => {
  return (
    <UserConsumer>
      {(user) => <Component {...props} user={user} />}
    </UserConsumer>
  );
};
