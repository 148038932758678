import Dashboard from "./Pages/Dashboard";
import Packages from "./Pages/Packages";
import Tours from "./Pages/Tours";
import Orders from "./Pages/Orders";
import Users from "./Pages/Users";
import Admin from "./Pages/Admin";
import Airlines from "./Pages/Airlines";
import Airports from "./Pages/Airports";
import {
  FiHome,
  FiDatabase,
  FiInbox,
  FiUserCheck,
  FiKey,
  FiNavigation ,
  FiGlobe,
  FiAirplay
} from "react-icons/fi";

export default [
  {
    path: "/",
    name: "Home",
    hidden: false,
    Icon: FiHome,
    component: Dashboard,
  },
  {
    path: "/packages",
    name: "Packages",
    hidden: false,
    Icon: FiDatabase,
    component: Packages,
  },
  {
    path: "/tours",
    name: "Tours",
    Icon: FiInbox,
    component: Tours,
  },
  {
    path: "/orders",
    name: "Orders",
    Icon: FiGlobe,
    component: Orders,
  },
  {
    path: "/airlines",
    name: "Airlines",
    Icon: FiNavigation,
    component: Airlines,
  },
  {
    path: "/airports",
    name: "Airports",
    Icon: FiAirplay,
    component: Airports,
  },
  {
    path: "/users",
    name: "Users",
    hidden: false,
    Icon: FiUserCheck,
    component: Users,
  },
  {
    path: "/admin",
    name: "Admin",
    hidden: true,
    Icon: FiKey,
    component: Admin,
  },

];
