import React, { useState, useMemo, useEffect } from "react";
import queries from "../helpers/queries";
import {
    FiEdit,
    FiX,
    FiTruck,
    FiUnlock,
    FiActivity,
    FiFileText
} from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Select from "react-select";
import _ from "lodash";
import Table from "../Components/table";
import Modal from "../Components/modal";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Country, State } from "country-state-city";

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const boolean = [
    {
        label: "Included",
        value: "active",
    },
    {
        label: "Not Included",
        value: "inactive",
    },
    {
        label: "Hidden",
        value: "hidden",
    },
];

const amenityList = [
    {
        name: "Free Travel Insurance",
        value: "insurance",
    },
    {
        name: "Free Wifi",
        value: "wifi",
    },
    {
        name: "Group Departure",
        value: "group",
    },
    {
        name: "Airport Taxes",
        value: "tax",
    },
    {
        name: "Check-in baggage",
        value: "checkin",
    },
    {
        name: "Tour Leader",
        value: "tourleader",
    },
    {
        name: "Hotel",
        value: "hotel",
    },
    {
        name: "Meals Onboard",
        value: "meals",
    },
    {
        name: "Tipping",
        value: "gratuties",
    },
    {
        name: "Full Payment Required",
        value: "fullpayment",
    },
    {
        name: "Easily Accessible",
        value: "accessible",
    },
    {
        name: "Premium Facilities",
        value: "facilities",
    },
    {
        name: "Pool Included",
        value: "pool",
    },
    {
        name: "Driver Provided",
        value: "driver",
    },
    {
        name: "All In",
        value: "allin",
    },
];

const formFields = [
    {
        name: "tourCode",
        label: "Tour Code",
    },
    {
        name: "tourDate",
        label: "Tour Date",
        type: "date",
    },
    {
        name: "childPrice",
        label: "Child Price",
        type: "number",
    },
    {
        name: "adultPrice",
        label: "Adult Price",
        type: "number",
    },
    {
        name: "childNoBedPrice",
        label: "Child No Bed Price",
        type: "number",
    },
    {
        name: "singlePrice",
        label: "Single Price",
        type: "number",
    },
    {
        name: "slots",
        label: "Available Slots",
        type: "number",
    },
];

const transitFlightFields = [
    {
        name: "transitStartTime",
        label: "Transit Start Time",
        type: "datetime-local",
    },
    {
        name: "transitEndTime",
        label: "Transit End Time",
        type: "datetime-local",
    },
];

const breakdownList = [
    {
        name: "Ground",
        value: "ground",
    },
    {
        name: "Adult Ground Package",
        value: "groundPackage",
    },
    {
        name: "Adult Singel Supp",
        value: "supp",
    },
    {
        name: "Adult Air ticket",
        value: "airticket",
    },
    {
        name: "Adult Tipping",
        value: "tipping",
    },
    {
        name: "Adult Travel Insurance",
        value: "insurance",
    },
    {
        name: "Adult Visa",
        value: "visa",
    },
    {
        name: "Child Ground Package",
        value: "childGroundPackage",
    },
    {
        name: "Child Singel Supp",
        value: "childSupp",
    },
    {
        name: "Child Air ticket",
        value: "childAirticket",
    },
    {
        name: "Child Tipping",
        value: "childTipping",
    },
    {
        name: "Child Travel Insurance",
        value: "childInsurance",
    },
    {
        name: "Child Visa",
        value: "childVisa",
    },
    {
        name: "Child No Bed Ground Package",
        value: "childNoBedGroundPackage",
    },
    {
        name: "Child No Bed Singel Supp",
        value: "childNoBedSupp",
    },
    {
        name: "Child No Bed Air ticket",
        value: "childNoBedAirticket",
    },
    {
        name: "Child No Bed Tipping",
        value: "childNoBedTipping",
    },
    {
        name: "Child No Bed Travel Insurance",
        value: "childNoBedInsurance",
    },
    {
        name: "Child No Bed Visa",
        value: "childNoBedVisa",
    },
    {
        name: "Single Ground Package",
        value: "singleGroundPackage",
    },
    {
        name: "Single Singel Supp",
        value: "singleSupp",
    },
    {
        name: "Single Air ticket",
        value: "singleAirticket",
    },
    {
        name: "Single Tipping",
        value: "singleTipping",
    },
    {
        name: "Single Travel Insurance",
        value: "singleInsurance",
    },
    {
        name: "Single Visa",
        value: "singleVisa",
    },
    
];

const flightFields = [
    {
        name: "code",
        label: "Code",
    },
    {
        name: "startTime",
        label: "Start Time",
        type: "datetime-local",
    },
    {
        name: "endTime",
        label: "End Time",
        type: "datetime-local",
    },
];

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    },
];

const Tours = ({ location }) => {
    const [modalData, setModalData] = useState({});
    const [amenitiesState, setAmenitiesState] = useState(false);
    const [airlinesData, setAirlinesData] = useState([]);
    const [airportsData, setAirportsData] = useState([]);
    const [editModalState, setEditModalState] = useState(false);
    const [tourStatusModalState, setTourStatusModalState] = useState(false);
    const [createModalState, setCreateModalState] = useState(false);
    const [breakdownModalState, setBreakdownModalState] = useState(false);
    const [editMemberState, setEditMemberState] = useState(false);
    const [editCommissionModal, setEditCommissionModal] = useState(false);

    const [filters, setFilters] = useState({
        package: location.state
            ? location.state.package
                ? location.state.package
                : null
            : null,
        date: "",
        status: null,
        unitNumber: "",
    });

    const {
        register,
        errors,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            airway: "",
            code: "",
            startCity: "",
            endCity: "",
            startTime: "",
            endTime: "",
            transitStartCity: "",
            transitEndCity: "",
            transitStartTime: "",
            transitEndTime: "",
            type: undefined,
            slots: 0,
        },
    });

    const [updateAmenities] = useMutation(queries.UPDATE_AMENITIES);

    const handleUpdateAmenities = async () => {
        const formValues = getValuesAmenitiesForm();

        await updateAmenities({
            variables: {
                _id: modalData._id,
                wifi: formValues.wifi.value,
                group: formValues.group.value,
                tax: formValues.tax.value,
                checkin: formValues.checkin.value,
                tourleader: formValues.tourleader.value,
                hotel: formValues.hotel.value,
                meals: formValues.meals.value,
                gratuties: formValues.gratuties.value,
                fullpayment: formValues.fullpayment.value,
                accessible: formValues.accessible.value,
                facilities: formValues.facilities.value,
                pool: formValues.pool.value,
                driver: formValues.driver.value,
                allin: formValues.allin.value,
                insurance: formValues.insurance.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchTours();
        setAmenitiesState(false);
        setModalData({});
    };

    const getAllCountries = () => {
        const CountryList = [
            Country.getCountryByCode("JP"),
            Country.getCountryByCode("KR"),
            Country.getCountryByCode("CN"),
            Country.getCountryByCode("TW"),
            Country.getCountryByCode("IN"),
            Country.getCountryByCode("TH"),
            Country.getCountryByCode("VN"),
        ];
        return CountryList.map((country) => ({
            value: country.isoCode, // Use isoCode as the value for the option
            label: country.name, // Use country name as the label for the option
        }));
    };

    const getStatesForCountry = (countryIsoCode) => {
        const states = State.getStatesOfCountry(countryIsoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
    };

    const {
        register: registerCommissionForm,
        errors: errorsCommissionForm,
        handleSubmit: handleSubmitCommissionForm,
        getValues: getValuesCommissionForm,
        setValue: setValuesCommissionForm,
        reset: resetCommissionForm,
    } = useForm({
        defaultValues: {
            commission: "",
        },
    });

    const {
        getValues: getValuesStatusForm,
        setValue: setValueStatusForm,
        reset: resetStatusForm,
        control: statusFormControl,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    const {
        handleSubmit: handleBreakdownSubmit,
        getValues: getValuesBreakdownForm,
        setValue: setValuesBreakdownForm,
        reset: resetBreakdownForm,
        register: registerBreakdown,
    } = useForm({
        defaultValues: {
            groundPackage: undefined,
            supp: undefined,
            airticket: undefined,
            tipping: undefined,
            insurance: undefined,
            visa: undefined,
        },
    });

    const {
        handleSubmit: handleAmenitiesSubmit,
        getValues: getValuesAmenitiesForm,
        setValue: setValuesAmenitiesForm,
        reset: resetAmenitiesForm,
        control: amenitiesFormControl,
    } = useForm({
        defaultValues: {
            wifi: undefined,
            group: undefined,
            tax: undefined,
            checkin: undefined,
            tourleader: undefined,
            hotel: undefined,
            meals: undefined,
            gratuties: undefined,
            fullpayment: undefined,
            accessible: undefined,
            facilities: undefined,
            pool: undefined,
            driver: undefined,
            allin: undefined,
            insurance: undefined,
        },
    });

    const resetAmenitiesModal = () => {
        setAmenitiesState(false);
        setModalData({});
        resetAmenitiesForm();
    };

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const customStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),
        }),
        [errors]
    );

    useEffect(() => {
        if (amenitiesState) {
            const {
                wifi,
                group,
                tax,
                checkin,
                tourleader,
                hotel,
                meals,
                gratuties,
                fullpayment,
                accessible,
                facilities,
                pool,
                driver,
                allin,
                insurance,
            } = modalData.amenities;

            setValuesAmenitiesForm([
                {
                    wifi: {
                        label:
                            wifi === "active"
                                ? "Included"
                                : wifi === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: wifi,
                    },
                },
                {
                    group: {
                        label:
                            group === "active"
                                ? "Included"
                                : group === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: group,
                    },
                },
                {
                    tax: {
                        label:
                            tax === "active"
                                ? "Included"
                                : tax === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: tax,
                    },
                },
                {
                    checkin: {
                        label:
                            checkin === "active"
                                ? "Included"
                                : checkin === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: checkin,
                    },
                },
                {
                    tourleader: {
                        label:
                            tourleader === "active"
                                ? "Included"
                                : tourleader === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: tourleader,
                    },
                },
                {
                    hotel: {
                        label:
                            hotel === "active"
                                ? "Included"
                                : hotel === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: hotel,
                    },
                },
                {
                    meals: {
                        label:
                            meals === "active"
                                ? "Included"
                                : meals === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: meals,
                    },
                },
                {
                    gratuties: {
                        label:
                            gratuties === "active"
                                ? "Included"
                                : gratuties === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: gratuties,
                    },
                },
                {
                    fullpayment: {
                        label:
                            fullpayment === "active"
                                ? "Included"
                                : fullpayment === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: fullpayment,
                    },
                },
                {
                    accessible: {
                        label:
                            accessible === "active"
                                ? "Included"
                                : accessible === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: accessible,
                    },
                },
                {
                    facilities: {
                        label:
                            facilities === "active"
                                ? "Included"
                                : facilities === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: facilities,
                    },
                },
                {
                    pool: {
                        label:
                            pool === "active"
                                ? "Included"
                                : pool === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: pool,
                    },
                },
                {
                    driver: {
                        label:
                            driver === "active"
                                ? "Included"
                                : driver === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: driver,
                    },
                },
                {
                    allin: {
                        label:
                            allin === "active"
                                ? "Included"
                                : allin === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: allin,
                    },
                },
                {
                    insurance: {
                        label:
                            insurance === "active"
                                ? "Included"
                                : insurance === "hidden"
                                ? "Hidden"
                                : "Not Included",
                        value: insurance,
                    },
                },
            ]);
        }
    }, [amenitiesState, modalData, setValuesAmenitiesForm]);

    const { data } = useQuery(queries.FETCH_AIRLINE, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirlinesData(data.fetchAirlines),
    });

    const { data: airportData } = useQuery(queries.FETCH_AIRPORTS, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirportsData(airportData.fetchAirports),
    });

    const [updateBreakdown] = useMutation(queries.UPDATE_BREAKDOWN);

    const handleUpdateBreakdown = async () => {
        const formValues = getValuesBreakdownForm();

        const totalAdultPrice =
            parseFloat(formValues.groundPackage) +
            parseFloat(formValues.supp) +
            parseFloat(formValues.airticket) +
            parseFloat(formValues.tipping) +
            parseFloat(formValues.insurance) +
            parseFloat(formValues.visa);
        const totalChildPrice =
            parseFloat(formValues.childGroundPackage) +
            parseFloat(formValues.childSupp) +
            parseFloat(formValues.childAirticket) +
            parseFloat(formValues.childTipping) +
            parseFloat(formValues.childInsurance) +
            parseFloat(formValues.childVisa);
        const totalChildNoBedPrice =
            parseFloat(formValues.childNoBedGroundPackage) +
            parseFloat(formValues.childNoBedSupp) +
            parseFloat(formValues.childNoBedAirticket) +
            parseFloat(formValues.childNoBedTipping) +
            parseFloat(formValues.childNoBedInsurance) +
            parseFloat(formValues.childNoBedVisa);
        const totalSinglePrice =
            parseFloat(formValues.singleGroundPackage) +
            parseFloat(formValues.singleSupp) +
            parseFloat(formValues.singleAirticket) +
            parseFloat(formValues.singleTipping) +
            parseFloat(formValues.singleInsurance) +
            parseFloat(formValues.singleVisa);
        if (
            totalAdultPrice !== parseFloat(modalData.adultPrice) ||
            totalChildPrice !== parseFloat(modalData.childPrice) ||
            totalChildNoBedPrice !== parseFloat(modalData.childNoBedPrice) ||
            totalSinglePrice !== parseFloat(modalData.singlePrice)
        ) {
            alert("Total price must be equal to the price in the tour details");
            return;
        }

        await updateBreakdown({
            variables: {
                _id: modalData._id,
                groundPackage: parseFloat(formValues.groundPackage),
                supp: parseFloat(formValues.supp),
                airticket: parseFloat(formValues.airticket),
                tipping: parseFloat(formValues.tipping),
                insurance: parseFloat(formValues.insurance),
                visa: parseFloat(formValues.visa),
                childGroundPackage: parseFloat(formValues.childGroundPackage),
                childSupp: parseFloat(formValues.childSupp),
                childAirticket: parseFloat(formValues.childAirticket),
                childTipping: parseFloat(formValues.childTipping),
                childInsurance: parseFloat(formValues.childInsurance),
                childVisa: parseFloat(formValues.childVisa),
                childNoBedGroundPackage: parseFloat(
                    formValues.childNoBedGroundPackage
                ),
                childNoBedSupp: parseFloat(formValues.childNoBedSupp),
                childNoBedAirticket: parseFloat(formValues.childNoBedAirticket),
                childNoBedTipping: parseFloat(formValues.childNoBedTipping),
                childNoBedInsurance: parseFloat(formValues.childNoBedInsurance),
                childNoBedVisa: parseFloat(formValues.childNoBedVisa),
                singleGroundPackage: parseFloat(formValues.singleGroundPackage),
                singleSupp: parseFloat(formValues.singleSupp),
                singleAirticket: parseFloat(formValues.singleAirticket),
                singleTipping: parseFloat(formValues.singleTipping),
                singleInsurance: parseFloat(formValues.singleInsurance),
                singleVisa: parseFloat(formValues.singleVisa),
                ground: parseFloat(formValues.ground),
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchTours();
        setBreakdownModalState(false);
        setModalData({});
    };

    const [createTour] = useMutation(queries.CREATE_CASE);

    const handleCreateTour = async () => {
        try {
            const formValues = getValues();

            const result = await createTour({
                variables: {
                    package: formValues.package.value,
                    tourCode: formValues.tourCode,
                    childPrice: parseFloat(formValues.childPrice),
                    adultPrice: parseFloat(formValues.adultPrice),
                    childNoBedPrice: parseFloat(formValues.childNoBedPrice),
                    singlePrice: parseFloat(formValues.singlePrice),
                    infantPrice: parseFloat(formValues.infantPrice),
                    tourDate: formValues.tourDate,
                    slots: parseFloat(formValues.slots),
                },
            });

            if (result.data) {
                resetCreateModal();
                refetchTours();
                alert("Success");
            }
        } catch (err) {
            alert("Tour Code cannot be duplicated");
            console.log(err);
        }
    };

    const [editTour] = useMutation(queries.EDIT_CASE);

    const handleEditTour = async () => {
        try {
            const formValues = getValues();

            const result = await editTour({
                variables: {
                    _id: modalData._id,
                    package: formValues.package.value,
                    tourCode: formValues.tourCode,
                    childPrice: parseFloat(formValues.childPrice),
                    adultPrice: parseFloat(formValues.adultPrice),
                    childNoBedPrice: parseFloat(formValues.childNoBedPrice),
                    singlePrice: parseFloat(formValues.singlePrice),
                    infantPrice: parseFloat(formValues.infantPrice),
                    tourDate: formValues.tourDate,
                    slots: parseFloat(formValues.slots),
                },
            });

            if (result.data) {
                refetchTours();
                setEditModalState(false);
                setModalData({});
                resetForm();
                alert("Success");
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [editCommission] = useMutation(queries.EDIT_COMMISSION);

    const handleEditCommission = async () => {
        try {
            const formValues = getValuesCommissionForm();

            const totalAmountOfComm = modalData.members.filter(
                (item) => item.member._id === modalData.nested.member._id
            );

            const result = await editCommission({
                variables: {
                    memberId: modalData.nested._id,
                    commission: parseFloat(
                        formValues.commission / totalAmountOfComm.length
                    ),
                },
            });

            if (result.data) {
                alert("Success");
                refetchTours();

                setModalData((prev) => ({
                    ...prev,
                    members: result.data.editCommission.members,
                    sponsorCommissions:
                        result.data.editCommission.sponsorCommissions,
                    leaderCommissions:
                        result.data.editCommission.leaderCommissions,
                }));

                setEditCommissionModal(false);
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [addFlightToTour] = useMutation(queries.ADD_FLIGHT_TO_TOUR);

    const handleAddFlightToTour = async ({
        airway,
        code,
        startCity,
        endCity,
        startTime,
        endTime,
        transitStartCity,
        transitEndCity,
        transitStartTime,
        transitEndTime,
        type,
    }) => {
        console.log("abvc")
        try {
            const result = await addFlightToTour({
                variables: {
                    tourId: modalData._id,
                    airway: `${airway.label}`,
                    code,
                    startCity: `${startCity.label} - ${startCity.value}`,
                    endCity: `${endCity.label} - ${endCity.value}`,
                    startTime,
                    endTime,
                    transitStartCity: `${transitStartCity.label} - ${transitStartCity.value}`,
                    transitEndCity: `${transitEndCity.label} - ${transitEndCity.value}`,
                    transitStartTime,
                    transitEndTime,
                    type: type.value,
                },
            });

            if (result.data) {
                resetForm();
                setValue("user", null);
                setModalData((prev) => ({
                    ...prev,
                    flights: result.data.addFlightToTour.flights,
                }));
                refetchTours();
                alert("Success");
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const { data: packagesAllData } = useQuery(queries.FETCH_PROJECTS, {
        fetchPolicy: "network-only",
        variables: {
            limit: 9999,
            page: 1,
            state: "active",
        },
        // onCompleted: ({ fetchPackages: { data } }) => setPackages(data),
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
        dir: "desc",
        by: "_id",
    });

    // 1: Create state to hold data & pagination
    const [departureTableData, setDeparturesTableData] = useState([]);
    const [departureTablePaginationState, setDepartureTablePaginationState] =
        useState({
            limit: 10,
            page: 1,
        });

    const [arrivalTableData, setArrivalTableData] = useState([]);

    const [arrivalTablePaginationState, setArrivalTablePaginationState] =
        useState({
            limit: 10,
            page: 1,
        });

    const {
        data: toursData,
        refetch: refetchTours,
        loading: toursLoading,
    } = useQuery(queries.FETCH_CASES, {
        fetchPolicy: "network-only",
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            by: paginationState.by ? paginationState.by : undefined,
            dir: paginationState.dir ? paginationState.dir : undefined,
            package: filters.package ? filters.package.value : undefined,
            date: filters.date,
            status: filters.status ? filters.status.value : undefined,
            unitNumber: filters.unitNumber,
            country: filters.country ? filters.country.value : undefined,
            state: filters.state ? filters.state.value : undefined,
        },
        onCompleted: ({ fetchTours: { pagination } }) => {
            setPaginationState(pagination);
        },
    });

    const { data: projectsData, refetch: refetchProjects } = useQuery(
        queries.FETCH_PROJECTS,
        {
            fetchPolicy: "network-only",
            variables: {
                limit: 99999,
                page: 1,
                status: "all",
            },
            // onCompleted: ({ fetchProjects: { data } }) => setProjects(data),
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        }
    );

    useEffect(() => {
        if (editCommissionModal) {
            const { _id } = modalData;

            setValuesCommissionForm([
                { commission: modalData.nested.commission },
                { _id },
            ]);
        }
    }, [editCommissionModal, modalData, setValuesCommissionForm]);

    useEffect(() => {
        if (breakdownModalState) {
            const {
                ground,
                groundPackage,
                supp,
                airticket,
                tipping,
                insurance,
                visa,
                childGroundPackage,
                childSupp,
                childAirticket,
                childTipping,
                childInsurance,
                childVisa,
                childNoBedGroundPackage,
                childNoBedSupp,
                childNoBedAirticket,
                childNoBedTipping,
                childNoBedInsurance,
                childNoBedVisa,
                singleGroundPackage,
                singleSupp,
                singleAirticket,
                singleTipping,
                singleInsurance,
                singleVisa,
            } = modalData.breakdown;

            setValuesBreakdownForm([
                { _id: modalData._id },
                {ground},
                { groundPackage },
                { supp },
                { airticket },
                { tipping },
                { insurance },
                { visa },
                { childGroundPackage },
                { childSupp },
                { childAirticket },
                { childTipping },
                { childInsurance },
                { childVisa },
                { childNoBedGroundPackage },
                { childNoBedSupp },
                { childNoBedAirticket },
                { childNoBedTipping },
                { childNoBedInsurance },
                { childNoBedVisa },
                { singleGroundPackage },
                { singleSupp },
                { singleAirticket },
                { singleTipping },
                { singleInsurance },
                { singleVisa },
            ]);
        }
    }, [
        editCommissionModal,
        breakdownModalState,
        modalData,
        setValuesCommissionForm,
        setValuesBreakdownForm,
    ]);

    useEffect(() => {
        if (tourStatusModalState) {
            const { _id } = modalData;
            setValueStatusForm([
                {
                    status: {
                        label: _.startCase(modalData.status),
                        value: modalData.status,
                    },
                },
                { _id },
            ]);
        }
    }, [tourStatusModalState, modalData, setValueStatusForm]);

    useEffect(() => {
        if (editModalState) {
            const {
                _id,
                childPrice,
                adultPrice,
                childNoBedPrice,
                singlePrice,
                tourCode,
                tourDate,
                slots,
                infantPrice,
            } = modalData;
            setValue([
                { _id },
                { childPrice: childPrice ? childPrice : 0 },
                { adultPrice: adultPrice ? adultPrice : 0 },
                { infantPrice: infantPrice ? infantPrice : 0 },
                { childNoBedPrice: childNoBedPrice ? childNoBedPrice : 0 },
                { singlePrice: singlePrice ? singlePrice : 0 },
                { tourCode },
                { tourDate: moment(parseInt(tourDate)).format("YYYY-MM-DD") },
                {
                    package: {
                        label: _.startCase(modalData.package.name),
                        value: modalData.package._id,
                    },
                },
                { slots: slots ? slots : 0 },
            ]);
        }
    }, [editModalState, modalData, setValue]);

    const resetCreateModal = () => {
        setCreateModalState(false);
        setModalData({});
        resetForm();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const resetCommissionModal = () => {
        setEditCommissionModal(false);
        resetCommissionForm();
    };

    const resetStatusModal = () => {
        setTourStatusModalState(false);
        setModalData({});
        resetStatusForm();
    };

    const resetMemberModal = () => {
        setEditMemberState(false);
    };

    const resetBreakdownModal = () => {
        setBreakdownModalState(false);
        setModalData({});
        resetBreakdownForm();
    };

    const columns = useMemo(
        () => [
            {
                Header: "Code",
                accessor: "tourCode",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Package",
                accessor: "package",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value.name}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Flights",
                id: "flights",
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="flex justify-center cursor-pointer"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });

                                setEditMemberState(true);
                            }}
                        >
                            <FiTruck />
                        </div>
                    );
                },
            },
            {
                Header: "Slots",
                accessor: "slots",
            },
            {
                Header: "Adult Price",
                accessor: "adultPrice",
                Cell: ({ value }) => (
                    <div>RM {NumberFormatter.format(value)}</div>
                ),
            },
            {
                Header: "Child No Bed Price",
                accessor: "childNoBedPrice",
                Cell: ({ value }) => (
                    <div>RM {NumberFormatter.format(value)}</div>
                ),
            },
            {
                Header: "Single Price",
                accessor: "singlePrice",
                Cell: ({ value }) => (
                    <div>RM {NumberFormatter.format(value)}</div>
                ),
            },
            {
                Header: "Child Price",
                accessor: "childPrice",
                Cell: ({ value }) => (
                    <div>RM {NumberFormatter.format(value)}</div>
                ),
            },
            {
                Header: "Tour Date",
                accessor: "tourDate",
                Cell: ({ value }) => (
                    <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                ),
            },
            {
                Header: "Created At",
                accessor: "createdAt",
                Cell: ({ value }) => (
                    <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                ),
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                Cell: ({ value }) => {
                    if (value) {
                        return <div className="capitalize">{value.name}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "",
                id: "actions",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center">
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    refetchProjects();
                                    setEditModalState(true);
                                }}
                            >
                                <FiEdit />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    setTourStatusModalState(true);
                                }}
                            >
                                <FiUnlock />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setAmenitiesState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiFileText />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setBreakdownModalState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiActivity />
                            </div>
                        </div>
                    );
                },
            },
        ],
        [setModalData, refetchProjects]
    );

    const [updateTourStatus] = useMutation(queries.UPDATE_CASE_STATUS);

    const [removeFlightFromTour] = useMutation(queries.REMOVE_FLIGHT_FROM_TOUR);

    const departColumns = useMemo(
        () => [
            {
                Header: "Airway",
                accessor: "airway",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Code",
                accessor: "code",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Start City",
                accessor: "startCity",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "End City",
                accessor: "endCity",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Start Time",
                accessor: "startTime",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD h:mm a")}
                    </div>
                ),
            },
            {
                Header: "End Time",
                accessor: "endTime",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Transit Start City",
                accessor: "transitStartCity",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Transit End City",
                accessor: "transitEndCity",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Transit Start Time",
                accessor: "transitStartTime",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Transit End Time",
                accessor: "transitEndTime",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Delete",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center justify-center">
                            <div className="flex items-center">
                                <div
                                    className="ml-4 cursor-pointer"
                                    onClick={async () => {
                                        try {
                                            const result =
                                                await removeFlightFromTour({
                                                    variables: {
                                                        tourId: modalData._id,
                                                        flightId: original._id,
                                                    },
                                                });
                                            if (result.data) {
                                                alert("Success");
                                                refetchTours();
                                                setModalData((prev) => ({
                                                    ...prev,
                                                    flights:
                                                        result.data
                                                            .removeFlightFromTour
                                                            .flights,
                                                }));
                                            }
                                        } catch (err) {
                                            alert("Failed");
                                            console.log(err);
                                        }
                                    }}
                                >
                                    <FiX color="red" />
                                </div>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [modalData, refetchTours, removeFlightFromTour]
    );

    const handleUpdateTourStatus = async () => {
        const formValues = getValuesStatusForm();

        await updateTourStatus({
            variables: {
                id: modalData._id,
                status: formValues.status.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchTours();
        setTourStatusModalState(false);
        setModalData({});
    };

    const paginateData = (array, { page, limit }) => {
        let updatedArray;
        let currentIndex = (page - 1) * limit;

        updatedArray = [...array].slice(currentIndex, currentIndex + limit);

        return updatedArray;
    };

    useEffect(() => {
        if (modalData.flights && departureTablePaginationState.page) {
            const departures = modalData.flights.filter(
                (item) => item.type === "depart"
            );
            setDeparturesTableData(
                paginateData(departures, departureTablePaginationState)
            );
        }
    }, [departureTablePaginationState, modalData.flights]);

    useEffect(() => {
        if (modalData.flights && arrivalTablePaginationState.page) {
            const arrivals = modalData.flights.filter(
                (item) => item.type === "arrive"
            );
            setArrivalTableData(
                paginateData(arrivals, arrivalTablePaginationState)
            );
        }
    }, [arrivalTablePaginationState, modalData.flights]);

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions lg:flex justify-between items-start mb-4">
                <div className="w-full pr-4">
                    <div className="lg:grid grid-cols-3 mb-4 col-gap-4 row-gap-2">
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                placeholder="Package"
                                isSearchable={true}
                                options={
                                    packagesAllData
                                        ? packagesAllData.fetchPackages.data.map(
                                              (pack) => ({
                                                  label: pack.name,
                                                  value: pack._id,
                                              })
                                          )
                                        : []
                                }
                                value={filters.package}
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        package: val,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={getAllCountries()}
                                value={filters.country}
                                placeholder="Country"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        country: val,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={
                                    filters.country
                                        ? getStatesForCountry(
                                              filters.country.value
                                          )
                                        : []
                                }
                                value={filters.state}
                                placeholder="State"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        state: val,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={false}
                                options={statusOptions}
                                value={filters.status}
                                placeholder="Status"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 mb-4 col-gap-4 row-gap-4">
                    <div
                        className="text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-addgreen hover:text-white"
                        onClick={() => {
                            refetchProjects();
                            setCreateModalState(true);
                        }}
                    >
                        <IoIosAdd className="inline" /> New Tour
                    </div>

                    <div
                        className="text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-iconblue hover:text-white"
                        onClick={() => {
                            setFilters({
                                project: null,
                                status: null,
                                date: "",
                                unitNumber: "",
                            });
                        }}
                    >
                        <IoIosAdd className="inline" /> Reset Filter
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                data={toursData ? toursData.fetchTours.data : []}
                updatePagination={setPaginationState}
                paginationState={paginationState}
                loading={toursLoading}
                pageCount={
                    toursData ? toursData.fetchTours.pagination.totalPages : 0
                }
                totalCount={
                    toursData ? toursData.fetchTours.pagination.documents : 0
                }
                sortable={[
                    "unitNumber",
                    "rebate",
                    "fees",
                    "bookingDate",
                    "netPrice",
                    "spaPrice",
                    "cashback",
                ]}
            />
            {editMemberState && (
                <Modal reset={resetMemberModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white p-6 rounded-md w-80vw lg:w-full">
                            <div className="lg:flex justify-between items-end mb-4">
                                <form
                                    className="lg:grid grid-cols-2 col-gap-8 w-9/12 items-center mr-8"
                                    onSubmit={handleSubmit(
                                        handleAddFlightToTour
                                    )}
                                >
                                    <div>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            Airway
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                />
                                            }
                                            name="airway"
                                            control={control}
                                            rules={{ required: true }}
                                            options={airlinesData.map(
                                                (airline) => ({
                                                    value: airline._id,
                                                    label: airline.name,
                                                })
                                            )}
                                        />
                                    </div>
                                    {flightFields.map((item, i) => (
                                        <div className="w-full" key={i}>
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <input
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                        errors[item.name]
                                                            ? "border-red-400"
                                                            : "border-gray-300"
                                                    }`}
                                                    name={item.name}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    placeholder={item.label}
                                                    type={
                                                        item.type
                                                            ? item.type
                                                            : "text"
                                                    }
                                                />
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        <div
                                            className="mb-2 text-sm text-lynch font-medium"
                                        >
                                            Start City
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                />
                                            }
                                            name="startCity"
                                            control={control}
                                            rules={{ required: true }}
                                            options={airportsData.map(
                                                (airline) => ({
                                                    value: `${airline.value}`,
                                                    label: `${airline.label} - ${airline.value}`,
                                                })
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <div
                                            className="mb-2 text-sm text-lynch font-medium"
                                        >
                                            End City
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                />
                                            }
                                            name="endCity"
                                            control={control}
                                            rules={{ required: true }}
                                            options={airportsData.map(
                                                (airline) => ({
                                                    value: `${airline.value}`,
                                                    label: `${airline.label} - ${airline.value}`,
                                                })
                                            )}
                                        />
                                    </div>

                                    {transitFlightFields.map((item, i) => (
                                        <div className="w-full">
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <input
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                        errors[item.name]
                                                            ? "border-red-400"
                                                            : "border-gray-300"
                                                    }`}
                                                    name={item.name}
                                                    ref={register({
                                                        required: false,
                                                    })}
                                                    placeholder={item.label}
                                                    type={
                                                        item.type
                                                            ? item.type
                                                            : "text"
                                                    }
                                                />
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <div>
                                        <div
                                            className="mb-2 text-sm text-lynch font-medium"
                                        >
                                            Transit Start City
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                />
                                            }
                                            name="transitStartCity"
                                            control={control}
                                            rules={{ required: false }}
                                            options={airportsData.map(
                                                (airline) => ({
                                                    value: `${airline.value}`,
                                                    label: `${airline.label} - ${airline.value}`,
                                                })
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div
                                            className="mb-2 text-sm text-lynch font-medium"
                                        >
                                            Transit End City
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={true}
                                                />
                                            }
                                            name="transitEndCity"
                                            control={control}
                                            rules={{ required: false }}
                                            options={airportsData.map(
                                                (airline) => ({
                                                    value: `${airline.value}`,
                                                    label: `${airline.label} - ${airline.value}`,
                                                })
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            Type
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                />
                                            }
                                            name="type"
                                            control={control}
                                            rules={{ required: true }}
                                            options={[
                                                {
                                                    label: "Depart",
                                                    value: "depart",
                                                },
                                                {
                                                    label: "Arrival",
                                                    value: "arrive",
                                                },
                                            ]}
                                        />
                                        {errors.type && (
                                            <p className="text-red-400 text-xs">{`Type cannot be empty`}</p>
                                        )}
                                    </div>
                                </form>
                                <div className="table-header-actions lg:w-3/12 mt-2 lg:mt-0">
                                    <div
                                        className="text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 w-full text-center hover:bg-iconpink hover:text-white"
                                        onClick={handleSubmit(
                                            handleAddFlightToTour
                                        )}
                                    >
                                        <IoIosAdd className="inline" /> Add
                                        Flight
                                    </div>
                                </div>
                            </div>
                            <div className="text-lynch mb-4">Departure</div>
                            {/* 5: Assign data & pagination to table, as well as calculate pageCount & totalCount using logic  */}
                            <Table
                                columns={departColumns}
                                data={departureTableData}
                                updatePagination={
                                    setDepartureTablePaginationState
                                }
                                paginationState={departureTablePaginationState}
                                pageCount={1}
                                totalCount={1}
                            />

                            <div className="text-lynch mb-4">Arrival</div>
                            <Table
                                columns={departColumns}
                                data={arrivalTableData}
                                updatePagination={
                                    setArrivalTablePaginationState
                                }
                                paginationState={arrivalTablePaginationState}
                                pageCount={
                                    modalData.sponsorCommissions
                                        ? Math.ceil(
                                              modalData.sponsorCommissions
                                                  .length /
                                                  arrivalTablePaginationState.limit
                                          )
                                        : []
                                }
                                totalCount={
                                    modalData.sponsorCommissions
                                        ? modalData.sponsorCommissions.length
                                        : ""
                                }
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {editCommissionModal && (
                <Modal reset={resetCommissionModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Commission
                            </div>
                            <form
                                onSubmit={handleSubmitCommissionForm(
                                    handleEditCommission
                                )}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Member Commission
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errorsCommissionForm.commission
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="commission"
                                        ref={registerCommissionForm({
                                            required: true,
                                        })}
                                        placeholder="Commission"
                                        type="number"
                                    />
                                    {errorsCommissionForm.commission && (
                                        <p className="text-red-400 text-xs">
                                            Commission cannot be empty
                                        </p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Tour
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditTour)}
                                className="lg:w-104"
                            >
                                <div className="mb-2">
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Package
                                    </div>
                                    <Controller
                                        as={
                                            <Select
                                                styles={customStyles}
                                                isSearchable={true}
                                            />
                                        }
                                        name="package"
                                        control={control}
                                        rules={{ required: true }}
                                        options={
                                            projectsData
                                                ? projectsData.fetchPackages
                                                    ? projectsData.fetchPackages.data.map(
                                                          (project) => ({
                                                              label: project.name,
                                                              value: project._id,
                                                          })
                                                      )
                                                    : []
                                                : []
                                        }
                                    />
                                    {errors.project && (
                                        <p className="text-red-400 text-xs">
                                            Project cannot be empty
                                        </p>
                                    )}
                                </div>
                                {formFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                            type={
                                                item.type ? item.type : "text"
                                            }
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {tourStatusModalState && (
                <Modal reset={resetStatusModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Status
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUpdateTourStatus)}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Status
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={customStyles}
                                            isSearchable={false}
                                        />
                                    }
                                    name="status"
                                    control={statusFormControl}
                                    rules={{ required: true }}
                                    options={[
                                        {
                                            label: "Active",
                                            value: "active",
                                        },
                                        {
                                            label: "Inactive",
                                            value: "inactive",
                                        },
                                    ]}
                                />
                                {errors.status && (
                                    <p className="text-red-400 text-xs">{`Status cannot be empty`}</p>
                                )}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {amenitiesState && (
                <Modal reset={resetAmenitiesModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Amenities
                            </div>
                            <form
                                onSubmit={handleAmenitiesSubmit(
                                    handleUpdateAmenities
                                )}
                                className="lg:w-104"
                            >
                                {amenityList.map((item, i) => {
                                    return (
                                        <div className="mb-2" key={i}>
                                            <div className="mb-2 text-sm text-lynch font-medium">
                                                {item.name}
                                            </div>
                                            <Controller
                                                as={
                                                    <Select
                                                        styles={customStyles}
                                                        isSearchable={false}
                                                    />
                                                }
                                                name={item.value}
                                                control={amenitiesFormControl}
                                                rules={{ required: false }}
                                                options={boolean}
                                            />
                                        </div>
                                    );
                                })}

                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                New Tour Form
                            </div>
                            <form
                                onSubmit={handleSubmit(handleCreateTour)}
                                className="lg:w-104"
                            >
                                <div className="mb-2">
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Package
                                    </div>
                                    <Controller
                                        as={
                                            <Select
                                                styles={customStyles}
                                                isSearchable={true}
                                            />
                                        }
                                        name="package"
                                        control={control}
                                        rules={{ required: true }}
                                        options={
                                            projectsData
                                                ? projectsData.fetchPackages
                                                    ? projectsData.fetchPackages.data.map(
                                                          (project) => ({
                                                              label: project.name,
                                                              value: project._id,
                                                          })
                                                      )
                                                    : []
                                                : []
                                        }
                                    />
                                    {errors.project && (
                                        <p className="text-red-400 text-xs">
                                            Project cannot be empty
                                        </p>
                                    )}
                                </div>
                                {formFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                            type={
                                                item.type ? item.type : "text"
                                            }
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {breakdownModalState && (
                <Modal reset={resetBreakdownModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Price Breakdown
                            </div>
                            <form
                                onSubmit={handleBreakdownSubmit(
                                    handleUpdateBreakdown
                                )}
                                className="lg:w-104"
                            >
                                {breakdownList.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.name}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.value}
                                            ref={registerBreakdown({
                                                required: false,
                                            })}
                                            placeholder={item.name}
                                            type={"number"}
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}

                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default withUser(Tours);
