import React, { useState, useMemo, useEffect, useCallback } from "react";
import queries from "../helpers/queries";
import Select from "react-select";
import Table from "../Components/table";
import Modal from "../Components/modal";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { FiEdit, FiUnlock, FiUser, FiShieldOff } from "react-icons/fi";

import axios from "axios";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
];

const rankingOptions = [
    {
        label: "Prime Leader",
        value: "primeleader",
    },
    {
        label: "Leader",
        value: "leader",
    },
    {
        label: "Agent",
        value: "agent",
    },
];

const types = [
    {
        label: "User",
        value: "user",
    },
    {
        label: "Admin",
        value: "admin",
    },
    {
        label: "Superadmin",
        value: "superadmin",
    },
];

const customRank = [
    {
        label: "Sales Director",
        value: "director",
    },
    {
        label: "Prime Leader",
        value: "primeleader",
    },
    {
        label: "None",
        value: "none",
    },
];

const userDetailsFormFields = [
    {
        name: "nickname",
        label: "Nickname",
    },
    {
        name: "phone",
        label: "Phone Number",
    },
    {
        name: "bankDetails",
        label: "Bank Account Number",
    },
];

const status = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
];

const formFields = [
    {
        name: "name",
        label: "Full Name",
    },
    {
        name: "ic",
        label: "IC Number",
        // type: "hiddenEdit",
    },
    {
        name: "nickname",
        label: "Nickname",
    },
    {
        name: "phone",
        label: "Phone Number",
    },
    {
        name: "bankDetails",
        label: "Bank Details",
    },
    {
        name: "personalSales",
        label: "Personal Sales",
    },
    {
        name: "groupSales",
        label: "Group Sales",
    },
    {
        name: "password",
        label: "Password",
        // type: "password",
    },
    {
        name: "code",
        label: "Code",
        type: "prefixed",
    },
];

let timerId = null;

const Admin = () => {
    const [rankings, setRankings] = useState([]);
    const [modalData, setModalData] = useState(true);
    const [userDetailsState, setUserDetailsState] = useState(false);
    const [statusModalState, setStatusModalState] = useState(false);
    const [rankingModalState, setRankingModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
        dir: "desc",
        by: "_id",
    });

    const apollo = useApolloClient();

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [filters, setFilters] = useState({
        name: undefined,
        code: undefined,
        status: "active",
        ranking: undefined,
        type: undefined,
        memberclub: undefined,
    });

    const {
        register,
        errors,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            name: "",
            image: "",
            referral: undefined,
            type: undefined,
            ranking: undefined,
            ic: "",
            password: "",
            phone: "",
            bankDetails: "",
            code: "",
            customRank: {
                label: "None",
                value: "none",
            },
            nickname: "",
            preleaderDate: "",
            leaderDate: "",
            smcDate: "",
            omcDate: "",
            pmcDate: "",
            emcDate: "",
            status: undefined,
        },
    });

    useEffect(() => {
        if (editModalState) {
            const {
                name,
                type,
                referral,
                ranking,
                preleaderDate,
                leaderDate,
                smcDate,
                omcDate,
                pmcDate,
                emcDate,
                ic,
                nickname,
                password,
                phone,
                bankDetails,
                code,
                personalSales,
                groupSales,
                customRank,
            } = modalData;

            const values = [
                { name },
                {
                    userType: {
                        label: _.startCase(type),
                        value: type,
                    },
                },
                {
                    userRanking: {
                        label: _.startCase(ranking.name),
                        value: ranking._id,
                    },
                },
                { ic },
                { nickname },
                { password },
                { phone },
                { bankDetails },
                { code },
                { preleaderDate },
                { leaderDate },
                { omcDate },
                { emcDate },
                { pmcDate },
                { smcDate },
                { personalSales },
                { groupSales },
                {
                    customRank: customRank
                        ? {
                              label: _.startCase(customRank),
                              value: customRank,
                          }
                        : {
                              label: "None",
                              value: "none",
                          },
                },
            ];

            if (referral) {
                values.push({
                    referral: {
                        label: referral.name,
                        value: referral._id,
                    },
                });
            }

            setValue(values);
        }
    }, [editModalState, modalData, setValue]);

    const {
        register: registerFilter,
        handleSubmit: handleFilterSubmit,
        getValues: getFilterValues,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const memberSelect = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const {
        data: usersData,
        refetch: refetchUsers,
        loading: usersLoading,
    } = useQuery(queries.FETCH_USERS, {
        fetchPolicy: "network-only",
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            dir: paginationState.dir,
            by: paginationState.by ? paginationState.by : undefined,
            name: filters.name,
            code: filters.code,
            status: filters.status,
            ranking: filters.ranking,
            type: filters.type,
            memberclub: filters.memberclub,
        },
        onCompleted: ({ fetchUsers: { pagination, data } }) => {
            setPaginationState(pagination);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    useQuery(queries.FETCH_RANKINGS, {
        fetchPolicy: "network-only",
        onCompleted: ({ fetchRankings: _id, name }) => {
            setRankings(_id, name);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const columns = useMemo(() => {
        if (filters.memberclub) {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div> {value}</div>;
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Yearly Commission",
                    accessor: "yearlyCommission",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "OMC Date",
                    accessor: "omcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "EMC Date",
                    accessor: "emcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "PMC Date",
                    accessor: "pmcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "SMC Date",
                    accessor: "smcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
            ];
        } else {
            return [
                {
                    Header: "User Details",
                    id: "userdetails",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div
                                className="flex justify-center cursor-pointer"
                                onClick={() => {
                                    setUserDetailsState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiUser />
                            </div>
                        );
                    },
                },
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div> {value}</div>;
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "IC",
                    accessor: "ic",
                },
                {
                    Header: "Code",
                    accessor: "code",
                },
                {
                    Header: "Personal Sales",
                    accessor: "personalSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Group Sales",
                    accessor: "groupSales",
                    Cell: ({ value }) => (
                        <div>{NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "Ranking",
                    accessor: "ranking",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize"> {value.name}</div>
                            );
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Leader Date",
                    accessor: "preleaderDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "Prime Leader Date",
                    accessor: "leaderDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "OMC Date",
                    accessor: "omcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "EMC Date",
                    accessor: "emcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "PMC Date",
                    accessor: "pmcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "SMC Date",
                    accessor: "smcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
               
                {
                    Header: "Upline",
                    accessor: "referral",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize"> {value.name}</div>
                            );
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "",
                    id: "actions",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div className="flex items-center">
                                <div
                                    className="cursor-pointer mr-2"
                                    onClick={() => {
                                        setEditModalState(true);
                                        setModalData({
                                            ...original,
                                        });
                                    }}
                                >
                                    <FiEdit />
                                </div>
                                <div
                                    className="cursor-pointer mr-2"
                                    onClick={async () => {
                                        setModalData({
                                            ...original,
                                        });
                                        setStatusModalState(true);
                                    }}
                                >
                                    <FiUnlock />
                                </div>
                                <div
                                    className="cursor-pointer"
                                    onClick={async () => {
                                        setModalData({
                                            ...original,
                                        });
                                        setRankingModalState(true);
                                    }}
                                >
                                    <FiShieldOff />
                                </div>
                            </div>
                        );
                    },
                },
            ];
        }
    }, [NumberFormatter, setModalData, filters.memberclub]);

    const resetUserDetailsModal = () => {
        setUserDetailsState(false);
        setModalData({});
        resetForm();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const resetStatusModal = () => {
        setStatusModalState(false);
        setModalData({});
        resetStatusForm();
    };

    const resetRankingModal = () => {
        setRankingModalState(false);
        setModalData({});
        resetRankingForm();
    };

    useEffect(() => {
        if (userDetailsState) {
            const { _id, nickname, phone, bankDetails } = modalData;
            setValue([{ _id }, { nickname }, { phone }, { bankDetails }]);
        }
    }, [userDetailsState, modalData, setValue]);

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    const [editUserDetails] = useMutation(queries.EDIT_USER_DETAILS);

    const handleUserDetails = async () => {
        try {
            const formValues = getValues();

            const result = await editUserDetails({
                variables: {
                    userId: modalData._id,
                    nickname: formValues.nickname,
                    phone: formValues.phone,
                    bankDetails: formValues.bankDetails,
                },
            });

            if (result.data) {
                setUserDetailsState(false);
                resetForm();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [updateStatus] = useMutation(queries.UPDATE_USER_STATUS);

    const handleUpdateUserStatus = async () => {
        const formValues = getValuesStatusForm();

        await updateStatus({
            variables: {
                id: modalData._id,
                status: formValues.userStatus.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchUsers();
        setStatusModalState(false);
        setModalData({});
    };

    const {
        getValues: getValuesStatusForm,
        setValue: setValueStatusForm,
        reset: resetStatusForm,
        control: statusFormControl,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    useEffect(() => {
        if (statusModalState) {
            const { _id } = modalData;
            setValueStatusForm([
                {
                    userStatus: {
                        label: _.startCase(modalData.status),
                        value: modalData.status,
                    },
                },
                { _id },
            ]);
        }
    }, [statusModalState, modalData, setValueStatusForm]);

    const [updateUserRanking] = useMutation(queries.UPDATE_USER_RANKING);

    const handleUpdateUserRanking = async () => {
        const formValues = getValuesRankingForm();

        await updateUserRanking({
            variables: {
                id: modalData._id,
                ranking: formValues.userRanking.value,
                preleaderDate: formValues.userLeaderDate,
                leaderDate: formValues.userPrimeleaderDate,
                omcDate: formValues.userOMCDate,
                pmcDate: formValues.userPMCDate,
                emcDate: formValues.userEMCDate,
                smcDate: formValues.userSMCDate,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchUsers();
        setRankingModalState(false);
        setModalData({});
    };

    const {
        register: registerRankingForm,
        getValues: getValuesRankingForm,
        setValue: setValueRankingForm,
        reset: resetRankingForm,
        control: rankingFormControl,
    } = useForm({
        defaultValues: {
            ranking: undefined,
            preleaderDate: "",
            leaderDate: "",
            omcDate:"",
            emcDate:"",
            pmcDate:"",
            smcDate:"",
        },
    });

    useEffect(() => {
        if (rankingModalState) {
            const { _id, ranking, preleaderDate, leaderDate, omcDate, emcDate, smcDate, pmcDate } = modalData;
            setValueRankingForm([
                {
                    userRanking: {
                        label: _.startCase(ranking.name),
                        value:
                            ranking._id === "5ec264312bde7cefbf119c4c"
                                ? "primeleader"
                                : ranking._id === "5ec2676c877421f075d0cbff"
                                ? "leader"
                                : ranking._id === "5ec5f937dfa89a2ccea9e9d9"
                                ? "agent"
                                : "",
                    },
                },
                { preleaderDate },
                { leaderDate },
                { omcDate },
                { emcDate },
                { pmcDate },
                { smcDate },
                { _id },
            ]);
        }
    }, [rankingModalState, modalData, setValueRankingForm]);

    const [editUser] = useMutation(queries.EDIT_USER);

    const handleEditUser = async () => {
        try {
            const formValues = getValues();

            let newImage = undefined;

            if (formValues.image.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.image[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "users");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await editUser({
                variables: {
                    userId: modalData._id,
                    name: formValues.name,
                    nickname: formValues.nickname,
                    ic: formValues.ic,
                    image: newImage ? newImage : modalData.image,
                    type: formValues.userType.value,
                    referral: formValues.referral
                        ? formValues.referral.value
                        : undefined,
                    // ranking: formValues.userRanking.value,
                    password: formValues.password,
                    phone: formValues.phone,
                    bankDetails: formValues.bankDetails,
                    code: formValues.code,
                    customRank: formValues.customRank.value,
                    personalSales: formValues.personalSales
                        ? parseFloat(formValues.personalSales)
                        : parseFloat(modalData.personalSales),
                    groupSales: formValues.groupSales
                        ? parseFloat(formValues.groupSales)
                        : parseFloat(modalData.groupSales),
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetEditModal();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const loadActiveUsers = useCallback(
        (val, callback) => {
            clearTimeout(timerId);
            timerId = setTimeout(async () => {
                const result = await apollo.query({
                    query: queries.FETCH_USERS,
                    variables: {
                        name: val,
                        // status: "active",
                        limit: 9999,
                        page: 1,
                    },
                    fetchPolicy: "network-only",
                });

                callback(
                    result.data.fetchUsers.data.map((item) => ({
                        label: item.name.concat(" (" + item.code + ")"),
                        value: item._id,
                    }))
                );
            }, 500);
        },
        [apollo]
    );

    const loadUsers = useCallback(
        (val, callback) => {
            clearTimeout(timerId);
            timerId = setTimeout(async () => {
                const result = await apollo.query({
                    query: queries.FETCH_USERS,
                    variables: {
                        name: val,
                        limit: 9999,
                        page: 1,
                    },
                    fetchPolicy: "network-only",
                });

                callback(
                    result.data.fetchUsers.data.map((item) => ({
                        label: item.name,
                        value: item._id,
                    }))
                );
            }, 500);
        },
        [apollo]
    );

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions flex items-start">
                <div className="w-full pr-4">
                    <form
                        className="grid grid-cols-3 mb-4 col-gap-4 row-gap-4"
                        onSubmit={handleFilterSubmit(() => {
                            const values = getFilterValues();

                            setFilters((prev) => ({
                                ...prev,
                                ...values,
                                status: values.status
                                    ? values.status.value
                                    : undefined,
                                ranking: values.userRanking
                                    ? values.userRanking.value
                                    : undefined,
                                type: values.userType
                                    ? values.userType.value
                                    : undefined,
                                memberclub: values.memberclub
                                    ? values.memberclub.value
                                    : undefined,
                            }));
                            setPaginationState((prev) => ({
                                ...prev,
                                page: 1,
                            }));
                        })}
                    >
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={true}
                                loadOptions={loadUsers}
                                placeholder="Name"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        name: val.label,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <input
                                className="w-full h-full px-4 rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs"
                                type="text"
                                ref={registerFilter()}
                                placeholder="Code"
                                name="code"
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={statusOptions}
                                placeholder="Status"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={[
                                    {
                                        label: "All",
                                        value: "all",
                                    },
                                    ...rankings.map((ranking) => ({
                                        label: _.startCase(ranking.name),
                                        value: ranking._id,
                                    })),
                                ]}
                                placeholder="Ranking"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        ranking: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={[
                                    {
                                        label: "All",
                                        value: "all",
                                    },
                                    ...types,
                                ]}
                                placeholder="User Type"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        type: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={[
                                    {
                                        label: "OMC",
                                        value: "omc",
                                    },
                                    {
                                        label: "EMC",
                                        value: "emc",
                                    },
                                    {
                                        label: "PMC",
                                        value: "pmc",
                                    },
                                    {
                                        label: "SMC",
                                        value: "smc",
                                    },
                                ]}
                                placeholder="Member Club"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        memberclub: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="flex">
                            <div className="hover:bg-specialblue text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2">
                                <button>Apply Filters</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Table
                columns={columns}
                data={usersData ? usersData.fetchUsers.data : []}
                updatePagination={setPaginationState}
                paginationState={paginationState}
                loading={usersLoading}
                pageCount={
                    usersData ? usersData.fetchUsers.pagination.totalPages : 0
                }
                totalCount={
                    usersData ? usersData.fetchUsers.pagination.documents : 0
                }
                sortable={[
                    "dreamPoint",
                    "personalSales",
                    "groupSales",
                    "leaderDate",
                    "preleaderDate",
                    "yearlyCommission",
                    "omcDate",
                    "emcDate",
                    "pmcDate",
                    "smcDate",
                ]}
            />
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit User
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditUser)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => {
                                    if (item.type === "prefixed") {
                                        return (
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <div className="flex items-center">
                                                    <div
                                                        style={{
                                                            padding: "0.6rem",
                                                        }}
                                                        className="bg-gray-300 rounded-l-lg"
                                                    >
                                                        DD
                                                    </div>
                                                    <input
                                                        className={`my-2 p-3 w-full rounded-r-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                            errors[item.name]
                                                                ? "border-red-400"
                                                                : "border-gray-300"
                                                        }`}
                                                        name={item.name}
                                                        ref={register({
                                                            required: false,
                                                        })}
                                                        placeholder={item.label}
                                                        type={
                                                            item.type
                                                                ? item.type
                                                                : "text"
                                                        }
                                                    />
                                                </div>
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={item.name}>
                                            <div
                                                className={`mb-2 text-sm text-lynch font-medium ${
                                                    item.type === "hiddenEdit"
                                                        ? "hidden"
                                                        : ""
                                                }`}
                                            >
                                                {item.label}
                                            </div>
                                            <input
                                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                    item.type === "hiddenEdit"
                                                        ? "hidden"
                                                        : ""
                                                } ${
                                                    errors[item.name]
                                                        ? "border-red-400"
                                                        : "border-gray-300"
                                                }`}
                                                name={item.name}
                                                ref={register({
                                                    required: false,
                                                })}
                                                placeholder={item.label}
                                                type={
                                                    item.type
                                                        ? item.type
                                                        : "text"
                                                }
                                            />
                                            {errors[item.name] && (
                                                <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                            )}
                                        </div>
                                    );
                                })}
                                <div className="w-full">
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        User Type
                                    </div>
                                    <Controller
                                        as={<Select styles={memberSelect} />}
                                        name="userType"
                                        control={control}
                                        rules={{ required: true }}
                                        options={types}
                                    />
                                    {errors.userType && (
                                        <p className="text-red-400 text-xs">
                                            User Type cannot be empty
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Custom Rank
                                    </div>
                                    <Controller
                                        as={
                                            <Select
                                                styles={memberSelect}
                                                isSearchable={false}
                                            />
                                        }
                                        name="customRank"
                                        control={control}
                                        rules={{ required: true }}
                                        options={customRank}
                                    />
                                    {errors.customRank && (
                                        <p className="text-red-400 text-xs">
                                            Custom Rank cannot be empty
                                        </p>
                                    )}
                                </div>
                                <div className="w-full">
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Sponsor
                                    </div>
                                    <Controller
                                        as={
                                            <AsyncSelect
                                                styles={memberSelect}
                                            />
                                        }
                                        name="referral"
                                        control={control}
                                        rules={{ required: true }}
                                        loadOptions={loadActiveUsers}
                                    />
                                    {errors.user && (
                                        <p className="text-red-400 text-xs">
                                            Sponsor cannot be empty
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    {modalData.image.length ? (
                                        <img
                                            className="max-w-64 max-h-64"
                                            src={modalData.image}
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.image
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="image"
                                        ref={register()}
                                        type="file"
                                    />
                                    {errors.image && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {statusModalState && (
                <Modal reset={resetStatusModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Status
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUpdateUserStatus)}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Status
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={memberSelect}
                                            isSearchable={false}
                                        />
                                    }
                                    name="userStatus"
                                    control={statusFormControl}
                                    rules={{ required: true }}
                                    options={status}
                                />
                                {errors.userStatus && (
                                    <p className="text-red-400 text-xs">{`Status cannot be empty`}</p>
                                )}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {rankingModalState && (
                <Modal reset={resetRankingModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Ranking
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUpdateUserRanking)}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Ranking
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={memberSelect}
                                            isSearchable={false}
                                        />
                                    }
                                    name="userRanking"
                                    control={rankingFormControl}
                                    rules={{ required: true }}
                                    options={rankingOptions}
                                />
                                {errors.userRanking && (
                                    <p className="text-red-400 text-xs">{`Status cannot be empty`}</p>
                                )}
                                <div className="my-2 text-sm text-lynch font-medium">
                                    Leader Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.leaderDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userLeaderDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="Leader Date"
                                    type="Date"
                                />
                                <div className="my-2 text-sm text-lynch font-medium">
                                    Prime Leader Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.primeleaderDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userPrimeleaderDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="PrimeLeader Date"
                                    type="Date"
                                />
                                <div className="my-2 text-sm text-lynch font-medium">
                                    OMC Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.omcDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userOMCDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="OMC Date"
                                    type="Date"
                                />
                                <div className="my-2 text-sm text-lynch font-medium">
                                    EMC Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.emcDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userEMCDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="EMC Date"
                                    type="Date"
                                />
                                <div className="my-2 text-sm text-lynch font-medium">
                                    PMC Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.pmcDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userPMCDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="PrimeLeader Date"
                                    type="Date"
                                />
                                <div className="my-2 text-sm text-lynch font-medium">
                                    SMC Date
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.smcDate
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="userSMCDate"
                                    ref={registerRankingForm({
                                        required: false,
                                    })}
                                    placeholder="SMC Date"
                                    type="Date"
                                />
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {userDetailsState && (
                <Modal reset={resetUserDetailsModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                User Details
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUserDetails)}
                                className="lg:w-104"
                            >
                                {userDetailsFormFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Admin;
