import React, { useState, useMemo, useEffect, useCallback } from "react";
import queries from "../helpers/queries";
import Select from "react-select";
import Table from "../Components/table";
import Modal from "../Components/modal";
import _ from "lodash";
// import NumPad from "react-numpad";
import AsyncSelect from "react-select/async";
import { IoIosAdd } from "react-icons/io";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { FiEdit, FiUnlock, FiUser } from "react-icons/fi";

import axios from "axios";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";

const statusOptions = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
];

const types = [
    {
        label: "User",
        value: "user",
    },
    {
        label: "Admin",
        value: "admin",
    },
    {
        label: "Superadmin",
        value: "superadmin",
    },
];


const userDetailsFormFields = [
    {
        name: "address",
        label: "Address",
    },
    {
        name: "city",
        label: "City",
    },
    {
        name: "postal",
        label: "Postal Code",
    },
    {
        name: "state",
        label: "State",
    },
    {
        name: "country",
        label: "Country",
    },
];

const status = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Suspended",
        value: "suspended",
    },
];

const formFields = [
    {
        name: "name",
        label: "Full Name",
    },
    {
        name: "username",
        label: "Username",
    },
    {
        name: "password",
        label: "Password",
        type: "password"
    },
    {
        name: "confirmPassword",
        label: "Confirm Password",
        type: "password"
    },
    {
        name: "phone",
        label: "Phone Number",
    },
    {
        name: "email", 
        label: "Email",
    },
    {
        name: "address", 
        label: "Address",
    },
    {
        name: "city", 
        label: "City",
    },
    {
        name: "postal", 
        label: "Postal",
    },
    {
        name: "state", 
        label: "State",
    },
    {
        name: "country", 
        label: "Country",
    },
];

let timerId = null;

const Users = () => {
    const [modalData, setModalData] = useState(true);
    const [secondReferralState, setSecondReferralState] = useState(false);
    const [createModalState, setCreateModalState] = useState(false);
    const [cp58ModalState, setCp58ModalState] = useState(false);
    const [userDetailsState, setUserDetailsState] = useState(false);
    const [statusModalState, setStatusModalState] = useState(false);
    // const [dreampointsModalState, setDreampointsModalState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
        dir: "desc",
        by: "_id",
    });

    const apollo = useApolloClient();

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [filters, setFilters] = useState({
        name: undefined,
        code: undefined,
        status: "active",
        ranking: undefined,
        type: undefined,
        memberclub: undefined,
        state: undefined,
    });

    const {
        register,
        errors,
        handleSubmit,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            name: "",
            username: "",
            password: "",
            phone: "",
            email: "",
            address: "",
            city: "",
            postal: "",
            state: "",
            country: "",
        },
    });

    const {
        register: registerCp58,
        handleSubmit: handleSubmitCp58,
        control: cp58control,
        getValues: getCp58Values,
        reset: resetCp58Form,
        errors: cp58errors,
    } = useForm({
        defaultValues: {
            id: undefined,
            cp58: "",
        },
    });

    useEffect(() => {
        if (editModalState) {
            const {
                name,
                type,
                username,
                password,
                phone,
                email,
                address,
                city,
                postal,
                state,
                country,
            } = modalData;

            const values = [
                { name },
                { type: { label: _.startCase(type), value: type } },
                { username },
                { password },
                { phone },
                { email },
                { address },
                { city },
                { postal },
                { state },
                { country },
            ];

            setValue(values);
        }
    }, [editModalState, modalData, setValue]);

    const {
        getValues: getSecondReferralValues,
        setValue: setSecondReferralValues,
        reset: resetSecondReferralForm,
        control: secondReferralFormControl,
    } = useForm({
        defaultValues: {
            secondReferral: undefined,
        },
    });

    useEffect(() => {
        if (secondReferralState) {
            const { secondReferral } = modalData;

            const values = [];

            if (secondReferral) {
                values.push({
                    secondReferral: {
                        label: secondReferral.name,
                        value: secondReferral._id,
                    },
                });
            }

            setSecondReferralValues(values);
        }
    }, [secondReferralState, modalData, setSecondReferralValues]);

    const {
        handleSubmit: handleFilterSubmit,
        getValues: getFilterValues,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const memberSelect = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const {
        data: usersData,
        refetch: refetchUsers,
        loading: usersLoading,
    } = useQuery(queries.FETCH_USERS, {
        fetchPolicy: "network-only",
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            dir: paginationState.dir,
            by: paginationState.by ? paginationState.by : undefined,
            name: filters.name,
            code: filters.code,
            status: filters.status,
            ranking: filters.ranking,
            type: filters.type,
            memberclub: filters.memberclub,
            state: filters.state,
        },
        onCompleted: ({ fetchUsers: { pagination, data } }) => {
            setPaginationState(pagination);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const columns = useMemo(() => {
        if (filters.memberclub) {
            return [
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div> {value}</div>;
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Yearly Commission",
                    accessor: "yearlyCommission",
                    Cell: ({ value }) => (
                        <div>RM {NumberFormatter.format(value)}</div>
                    ),
                },
                {
                    Header: "OMC Date",
                    accessor: "omcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "EMC Date",
                    accessor: "emcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "PMC Date",
                    accessor: "pmcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
                {
                    Header: "SMC Date",
                    accessor: "smcDate",
                    Cell: ({ value }) => {
                        if (value) {
                            return (
                                <div className="capitalize">
                                    {" "}
                                    {moment(value, "x").format("DD-MM-YYYY")}
                                </div>
                            );
                        }

                        return <div>-</div>;
                    },
                },
            ];
        } else {
            return [
                {
                    Header: "User Details",
                    id: "userdetails",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div
                                className="flex justify-center cursor-pointer"
                                onClick={() => {
                                    setUserDetailsState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiUser />
                            </div>
                        );
                    },
                },
                {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div> {value}</div>;
                        }

                        return <div></div>;
                    },
                },
                {
                    Header: "Phone Number",
                    accessor: "phone",
                },
                {
                    Header: "Email",
                    accessor: "email",
                },
                {
                    Header: "Status",
                    accessor: "status",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div className="capitalize">{value}</div>;
                        }
    
                        return <div></div>;
                    },
                },
                {
                    Header: "Created At",
                    accessor: "createdAt",
                    Cell: ({ value }) => (
                        <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                    ),
                },
                {
                    Header: "Created By",
                    accessor: "createdBy",
                    Cell: ({ value }) => {
                        if (value) {
                            return <div className="capitalize">{value.name}</div>;
                        }
    
                        return <div></div>;
                    },
                },
                {
                    Header: "",
                    id: "actions",
                    Cell: ({ row: { original } }) => {
                        return (
                            <div className="flex items-center">
                                <div
                                    className="cursor-pointer mr-2"
                                    onClick={() => {
                                        setEditModalState(true);
                                        setModalData({
                                            ...original,
                                        });
                                    }}
                                >
                                    <FiEdit />
                                </div>
                                <div
                                    className="cursor-pointer mr-2"
                                    onClick={async () => {
                                        setModalData({
                                            ...original,
                                        });
                                        setStatusModalState(true);
                                    }}
                                >
                                    <FiUnlock />
                                </div>
                               
                            </div>
                        );
                    },
                },
            ];
        }
    }, [NumberFormatter, setModalData, filters.memberclub]);

    const resetCreateModal = () => {
        setCreateModalState(false);
        setModalData({});
        resetForm();
    };

    const resetCp58Modal = () => {
        setCp58ModalState(false);
        setModalData({});
        resetCp58Form();
    };

    const resetUserDetailsModal = () => {
        setUserDetailsState(false);
        setModalData({});
        resetForm();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const resetStatusModal = () => {
        setStatusModalState(false);
        setModalData({});
        resetStatusForm();
    };

    const resetSecondReferralModal = () => {
        setSecondReferralState(false);
        setModalData({});
        resetSecondReferralForm();
    };

    // const resetDreamPointsModal = () => {
    //   setDreampointsModalState(false);
    //   setModalData({});
    // };

    useEffect(() => {
        if (userDetailsState) {
            const { _id, address, city, postal, state, country } =
                modalData;
            setValue([
                { _id },
                { address },
                { city },
                { postal },
                { state },
                { country },
            ]);
        }
    }, [userDetailsState, modalData, setValue]);

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    const [createUser] = useMutation(queries.CREATE_USER, {
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    alert(graphQLError.message);
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const [addCp58ToUser] = useMutation(queries.ADD_CP58_TO_USER, {
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    alert(graphQLError.message);
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const [editUserDetails] = useMutation(queries.EDIT_USER_DETAILS);

    const handleUserDetails = async () => {
        try {
            const formValues = getValues();

            const result = await editUserDetails({
                variables: {
                    userId: modalData._id,
                    address: formValues.address,
                    city: formValues.city,
                    postal: formValues.postal,
                    state: formValues.state,
                    country: formValues.country,
                },
            });

            if (result.data) {
                setUserDetailsState(false);
                resetForm();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const handleCreateUser = async () => {
        try {
            const formValues = getValues();

            if (formValues.password !== formValues.confirmPassword) {
                alert("Passwords do not match");
                return;
            }

            const result = await createUser({
                variables: {
                    name: formValues.name,
                    username: formValues.username,
                    password: formValues.password,
                    phone: formValues.phone,
                    email: formValues.email,
                    address: formValues.address,
                    city: formValues.city,
                    postal: formValues.postal,
                    state: formValues.state,
                    country: formValues.country,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetCreateModal();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const handleAddCp58ToUser = async () => {
        try {
            const formValues = getCp58Values();

            let newImage = undefined;

            if (formValues.cp58.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.cp58[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.cp58[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "cp58");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await addCp58ToUser({
                variables: {
                    id: formValues.id.value,
                    cp58: newImage ? newImage : "",
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                setModalData({});
                resetCp58Form();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const [updateSecondReferral] = useMutation(queries.UPDATE_SECOND_REFERRAL);

    const handleUpdateSecondReferral = async () => {
        const formValues = getSecondReferralValues();

        await updateSecondReferral({
            variables: {
                id: modalData._id,
                secondReferral: formValues.secondReferral.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchUsers();
        setSecondReferralState(false);
        setModalData({});
    };

    const handleDeleteSecondReferral = async () => {
        await updateSecondReferral({
            variables: {
                id: modalData._id,
                secondReferral: 10001,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchUsers();
        setSecondReferralState(false);
        setModalData({});
    };


    const [updateStatus] = useMutation(queries.UPDATE_USER_STATUS);

    const handleUpdateUserStatus = async () => {
        const formValues = getValuesStatusForm();

        await updateStatus({
            variables: {
                id: modalData._id,
                status: formValues.userStatus.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchUsers();
        setStatusModalState(false);
        setModalData({});
    };
    const {
        getValues: getValuesStatusForm,
        setValue: setValueStatusForm,
        reset: resetStatusForm,
        control: statusFormControl,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    useEffect(() => {

        if (statusModalState) {
            const { _id } = modalData;
            setValueStatusForm([
                {
                    userStatus: {
                        label: _.startCase(modalData.status),
                        value: modalData.status,
                    },
                },
                { _id },
            ]);
        }
    }, [statusModalState, modalData, setValueStatusForm]);



    const [editUser] = useMutation(queries.EDIT_USER);

    const handleEditUser = async () => {
        try {
            const formValues = getValues();

            if (formValues.password !== formValues.confirmPassword) {
                alert("Passwords do not match");
                return;
            }

            const result = await editUser({
                variables: {
                    userId: modalData._id,
                    name: formValues.name,
                    username: formValues.username,
                    password: formValues.password !== "" ? formValues.password : undefined,
                    phone: formValues.phone,
                    email: formValues.email,
                    address: formValues.address,
                    city: formValues.city,
                    postal: formValues.postal,
                    state: formValues.state,
                    country: formValues.country,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetEditModal();
                refetchUsers();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const loadActiveUsers = useCallback(
        (val, callback) => {
            clearTimeout(timerId);
            timerId = setTimeout(async () => {
                const result = await apollo.query({
                    query: queries.FETCH_USERS,
                    variables: {
                        name: val,
                        limit: 9999,
                        page: 1,
                    },
                    fetchPolicy: "network-only",
                });

                callback(
                    result.data.fetchUsers.data.map((item) => ({
                        label: item.name.concat(" (" + item.code + ")"),
                        value: item._id,
                    }))
                );
            }, 500);
        },
        [apollo]
    );

    const loadUsers = useCallback(
        (val, callback) => {
            clearTimeout(timerId);
            timerId = setTimeout(async () => {
                const result = await apollo.query({
                    query: queries.FETCH_USERS,
                    variables: {
                        name: val,
                        limit: 9999,
                        page: 1,
                    },
                    fetchPolicy: "network-only",
                });

                callback(
                    result.data.fetchUsers.data.map((item) => ({
                        label: `${item.name} - (${item.nickname})`,
                        value: item.name,
                    }))
                );
            }, 500);
        },
        [apollo]
    );

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions lg:flex justify-end items-start">
                <div className="lg:w-11/12 lg:pr-4">
                    <form
                        className="lg:grid grid-cols-3 mb-4 col-gap-4 row-gap-4"
                        onSubmit={handleFilterSubmit(() => {
                            const values = getFilterValues();

                            setFilters((prev) => ({
                                ...prev,
                                ...values,
                                status: values.status
                                    ? values.status.value
                                    : undefined,
                                ranking: values.userRanking
                                    ? values.userRanking.value
                                    : undefined,
                                type: values.userType
                                    ? values.userType.value
                                    : undefined,
                                memberclub: values.memberclub
                                    ? values.memberclub.value
                                    : undefined,
                            }));
                            setPaginationState((prev) => ({
                                ...prev,
                                page: 1,
                            }));
                        })}
                    >
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={true}
                                loadOptions={loadUsers}
                                placeholder="Name"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        name: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={statusOptions}
                                placeholder="Status"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val.value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <AsyncSelect
                                styles={filterSelectStyles}
                                isSearchable={false}
                                defaultOptions={[
                                    {
                                        label: "All",
                                        value: "all",
                                    },
                                    ...types,
                                ]}
                                placeholder="User Type"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        type: val.value,
                                    }))
                                }
                            />
                        </div>
                    </form>
                </div>
                <div>
                    <div
                                                        className="text-medium border-solid cursor-pointer border border-gray-400 mb-4 lg:mt-0 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-iconblue hover:text-white"
                        onClick={() => setCreateModalState(true)}
                    >
                        <IoIosAdd /> Add User
                    </div>
                    <div
                        className="text-medium border-solid cursor-pointer border border-gray-400 mb-4 lg:mt-0 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-iconblue hover:text-white"
                        onClick={() => setCp58ModalState(true)}
                    >
                        CP58
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                data={usersData ? usersData.fetchUsers.data : []}
                updatePagination={setPaginationState}
                paginationState={paginationState}
                loading={usersLoading}
                pageCount={
                    usersData ? usersData.fetchUsers.pagination.totalPages : 0
                }
                totalCount={
                    usersData ? usersData.fetchUsers.pagination.documents : 0
                }
                sortable={[
                    "dreamPoint",
                    "personalSales",
                    "groupSales",
                    "leaderDate",
                    "preleaderDate",
                    "yearlyCommission",
                    "omcDate",
                    "emcDate",
                    "pmcDate",
                    "smcDate",
                ]}
            />
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit User
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditUser)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => {
                                    if (item.type === "prefixed") {
                                        return (
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <div className="flex items-center">
                                                    <div
                                                        style={{
                                                            padding: "0.6rem",
                                                        }}
                                                        className="bg-gray-300 rounded-l-lg"
                                                    >
                                                        DD
                                                    </div>
                                                    <input
                                                        className={`my-2 p-3 w-full rounded-r-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                            errors[item.name]
                                                                ? "border-red-400"
                                                                : "border-gray-300"
                                                        }`}
                                                        name={item.name}
                                                        ref={register({
                                                            required: false,
                                                        })}
                                                        placeholder={item.label}
                                                        type={
                                                            item.type
                                                                ? item.type
                                                                : "text"
                                                        }
                                                    />
                                                </div>
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={item.name}>
                                            <div
                                                className={`mb-2 text-sm text-lynch font-medium ${
                                                    item.type === "hiddenEdit"
                                                        ? "hidden"
                                                        : ""
                                                }`}
                                            >
                                                {item.label}
                                            </div>
                                            <input
                                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                    item.type === "hiddenEdit"
                                                        ? "hidden"
                                                        : ""
                                                } ${
                                                    errors[item.name]
                                                        ? "border-red-400"
                                                        : "border-gray-300"
                                                }`}
                                                name={item.name}
                                                ref={register({
                                                    required: false,
                                                })}
                                                placeholder={item.label}
                                                type={
                                                    item.type
                                                        ? item.type
                                                        : "text"
                                                }
                                            />
                                            {errors[item.name] && (
                                                <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                            )}
                                        </div>
                                    );
                                })}

                             
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {statusModalState && (
                <Modal reset={resetStatusModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Status
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUpdateUserStatus)}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Status
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={memberSelect}
                                            isSearchable={false}
                                        />
                                    }
                                    name="userStatus"
                                    className="mb-2"
                                    control={statusFormControl}
                                    rules={{ required: true }}
                                    options={status}
                                />
                                {errors.userStatus && (
                                    <p className="text-red-400 text-xs">{`Status cannot be empty`}</p>
                                )}

                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {secondReferralState && (
                <Modal reset={resetSecondReferralModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Second Upline Referral
                            </div>
                            <form
                                onSubmit={handleSubmit(
                                    handleUpdateSecondReferral
                                )}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Second Upline
                                </div>
                                <Controller
                                    as={<AsyncSelect styles={memberSelect} />}
                                    name="secondReferral"
                                    control={secondReferralFormControl}
                                    rules={{ required: true }}
                                    loadOptions={loadActiveUsers}
                                />
                                {errors.secondReferral && (
                                    <p className="text-red-400 text-xs">{`Second Referral cannot be empty`}</p>
                                )}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                               
                            </form>
                            <button onClick={() =>handleDeleteSecondReferral()} className="mt-2 py-3 text-sm rounded-lg w-full bg-iconpink bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Delete Second Upline
                                </button>
                        </div>
                    </div>
                </Modal>
            )}
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                New User
                            </div>
                            <form
                                onSubmit={handleSubmit(handleCreateUser)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => {
                                    if (item.type === "prefixed") {
                                        return (
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <div className="flex items-center">
                                                    <div
                                                        style={{
                                                            padding: "0.6rem",
                                                        }}
                                                        className="bg-gray-300 rounded-l-lg"
                                                    >
                                                        DD
                                                    </div>
                                                    <input
                                                        className={`my-2 p-3 w-full rounded-r-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                            errors[item.name]
                                                                ? "border-red-400"
                                                                : "border-gray-300"
                                                        }`}
                                                        name={item.name}
                                                        ref={register({
                                                            required: false,
                                                        })}
                                                        placeholder={item.label}
                                                        type={
                                                            item.type
                                                                ? item.type
                                                                : "text"
                                                        }
                                                    />
                                                </div>
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={item.name}>
                                            <div className="mb-2 text-sm text-lynch font-medium">
                                                {item.label}
                                            </div>
                                            <input
                                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                    errors[item.name]
                                                        ? "border-red-400"
                                                        : "border-gray-300"
                                                }`}
                                                name={item.name}
                                                ref={register({
                                                    required: false,
                                                })}
                                                placeholder={item.label}
                                                type={
                                                    item.type
                                                        ? item.type
                                                        : "text"
                                                }
                                            />
                                            {errors[item.name] && (
                                                <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                            )}
                                        </div>
                                    );
                                })}
                                        
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {cp58ModalState && (
                <Modal reset={resetCp58Modal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Add CP58 To User
                            </div>
                            <form
                                onSubmit={handleSubmitCp58(handleAddCp58ToUser)}
                                className="lg:w-104"
                            >
                                <div className="w-full">
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        User
                                    </div>
                                    <Controller
                                        as={
                                            <AsyncSelect
                                                styles={memberSelect}
                                            />
                                        }
                                        name="id"
                                        control={cp58control}
                                        rules={{ required: true }}
                                        loadOptions={loadActiveUsers}
                                    />
                                    {cp58errors.id && (
                                        <p className="text-red-400 text-xs">
                                            User cannot be empty
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        CP58
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.cp58
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="cp58"
                                        ref={registerCp58()}
                                        type="file"
                                        key="cp58"
                                    />
                                    {cp58errors.cp58 && (
                                        <p className="text-red-400 text-xs">{`CP58 cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {/* {dreampointsModalState && (
        <Modal reset={resetDreamPointsModal}>
          <div className="container h-full mx-auto flex justify-center items-center">
                              <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
              <div className="mb-4 text-3xl font-bold">DreamPoints</div>
              <form
                onSubmit={handleSubmit(handleDreampoints)}
                className="lg:w-104"
              >
                <div className="w-full">
                  <div className="mb-2 text-sm text-lynch font-medium">
                    Balance
                  </div>
                  <input
                    disabled
                    className={`p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs`}
                    placeholder="Balance"
                    value={modalData.dreamPoint}
                  />
                </div>
                <div className="grid grid-cols-2 col-gap-8 mt-4">
                  <div className="">
                    <Controller
                      control={control}
                      name="minus"
                      as={
                        <NumPad.Number
                          decimal={false}
                          negative={false}
                          placeholder={"Minus"}
                          name="minus"
                        >
                          <div className="mb-2 text-sm text-lynch font-medium">
                            -
                          </div>
                          <input
                            className={`p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                              errors["commission"]
                                ? "border-red-400"
                                : "border-gray-300"
                            }`}
                          />
                        </NumPad.Number>
                      }
                    />
                  </div>
                  <div className="">
                    <Controller
                      control={control}
                      name="plus"
                      as={
                        <NumPad.Number
                          decimal={false}
                          negative={false}
                          placeholder={"Add"}
                          name="plus"
                        >
                          <div className="mb-2 text-sm text-lynch font-medium">
                            +
                          </div>
                          <input
                            className={`p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                              errors["commission"]
                                ? "border-red-400"
                                : "border-gray-300"
                            }`}
                          />
                        </NumPad.Number>
                      }
                    />
                  </div>
                </div>
                <button className="mt-8 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal>
      )} */}
            {userDetailsState && (
                <Modal reset={resetUserDetailsModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                User Details
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUserDetails)}
                                className="lg:w-104"
                            >
                                {userDetailsFormFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Users;
