import React, { useState, useMemo, useEffect } from "react";
import queries from "../helpers/queries";
import { FiEdit, FiX, FiUser, FiFile, FiPlus } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Select from "react-select";
import _ from "lodash";
import Table from "../Components/advancedTable";
import Modal from "../Components/modal";
import moment from "moment";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { Country, State } from "country-state-city";
import orderConfirmation from "../Components/orderConfirmation";
import axios from "axios";

const formFields = [
    {
        name: "childPax",
        label: "Child Pax",
        type: "number",
    },
    {
        name: "adultPax",
        label: "Adult Pax",
        type: "number",
    },
    {
        name: "childnobedPax",
        label: "Child No Bed Pax",
        type: "number",
    },
    {
        name: "singlePax",
        label: "Single Pax",
        type: "number",
    },
    {
        name: "price",
        label: "Price",
        type: "number",
    },
    {
        name: "discount",
        label: "Discount",
        type: "number",
    },
];

const breakdownList = [
    {
        name: "Ground Package",
        value: "groundPackage",
    },
    {
        name: "Singel Supp",
        value: "supp",
    },
    {
        name: "Air ticket",
        value: "airticket",
    },
    {
        name: "Tipping",
        value: "tipping",
    },
    {
        name: "Travel Insurance",
        value: "insurance",
    },
    {
        name: "Visa",
        value: "visa",
    },
];

const memberFields = [
    {
        name: "name",
        label: "Name",
    },
    {
        name: "dob",
        label: "Date of Birth",
        type: "datetime-local",
    },
    {
        name: "passport",
        label: "Passport",
    },
    {
        name: "phone",
        label: "Phone",
    },
    {
        name: "remark",
        label: "Remark",
    },
];

const statusOptions = [
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
];

const Orders = ({ location }) => {
    const [modalData, setModalData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [airlinesData, setAirlinesData] = useState([]);
    const [editModalState, setEditModalState] = useState(false);
    const [orderStatusModalState, setOrderStatusModalState] = useState(false);
    const [createModalState, setCreateModalState] = useState(false);
    const [breakdownModalState, setBreakdownModalState] = useState(false);
    const [editMemberState, setEditMemberState] = useState(false);
    const [editCommissionModal, setEditCommissionModal] = useState(false);
    const [galleryModalState, setGalleryModalState] = useState(false);

    const apollo = useApolloClient();

    const [filters, setFilters] = useState({
        package: location.state
            ? location.state.package
                ? location.state.package
                : null
            : null,
        date: "",
        status: null,
        unitNumber: "",
    });

    const {
        register,
        errors,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            airway: "",
            code: "",
            startCity: "",
            endCity: "",
            startTime: "",
            endTime: "",
            transitStartCity: "",
            transitEndCity: "",
            transitStartTime: "",
            transitEndTime: "",
            type: undefined,
        },
    });

    const {
        register: registerCommissionForm,
        errors: errorsCommissionForm,
        handleSubmit: handleSubmitCommissionForm,
        getValues: getValuesCommissionForm,
        setValue: setValuesCommissionForm,
        reset: resetCommissionForm,
    } = useForm({
        defaultValues: {
            commission: "",
        },
    });

    const {
        getValues: getValuesStatusForm,
        setValue: setValueStatusForm,
        reset: resetStatusForm,
        control: statusFormControl,
    } = useForm({
        defaultValues: {
            status: null,
        },
    });

    const {
        handleSubmit: handleBreakdownSubmit,
        getValues: getValuesBreakdownForm,
        setValue: setValuesBreakdownForm,
        reset: resetBreakdownForm,
        register: registerBreakdown,
    } = useForm({
        defaultValues: {
            groundPackage: undefined,
            supp: undefined,
            airticket: undefined,
            tipping: undefined,
            insurance: undefined,
            visa: undefined,
        },
    });

    const {
        register: galleryRegister,
        errors: galleryErrors,
        handleSubmit: handleGallerySubmit,
        getValues: getGalleryValues,
        reset: resetGallery,
    } = useForm({
        defaultValues: {
            image: "",
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const customStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),
        }),
        [errors]
    );

    const { data } = useQuery(queries.FETCH_AIRLINE, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirlinesData(data.fetchAirlines),
    });

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    const [updateBreakdown] = useMutation(queries.UPDATE_BREAKDOWN);

    const handleUpdateBreakdown = async () => {
        const formValues = getValuesBreakdownForm();

        await updateBreakdown({
            variables: {
                _id: modalData._id,
                groundPackage: parseFloat(formValues.groundPackage),
                supp: parseFloat(formValues.supp),
                airticket: parseFloat(formValues.airticket),
                tipping: parseFloat(formValues.tipping),
                insurance: parseFloat(formValues.insurance),
                visa: parseFloat(formValues.visa),
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchOrders();
        setBreakdownModalState(false);
        setModalData({});
    };

    const [createOrder] = useMutation(queries.CREATE_CASE);

    const handleCreateOrder = async () => {
        try {
            const formValues = getValues();

            const result = await createOrder({
                variables: {
                    package: formValues.package.value,
                    tourCode: formValues.tourCode,
                    childPrice: parseFloat(formValues.childPrice),
                    adultPrice: parseFloat(formValues.adultPrice),
                    childNoBedPrice: parseFloat(formValues.childNoBedPrice),
                    singlePrice: parseFloat(formValues.singlePrice),
                    tourDate: formValues.tourDate,
                    slots: parseFloat(formValues.slots),
                },
            });

            if (result.data) {
                resetCreateModal();
                refetchOrders();
                alert("Success");
            }
        } catch (err) {
            alert("Order Code cannot be duplicated");
            console.log(err);
        }
    };

    const [editOrder] = useMutation(queries.EDIT_ORDER);

    const handleEditOrder = async () => {
        try {
            const formValues = getValues();

            const result = await editOrder({
                variables: {
                    _id: modalData._id,
                    adultPax: parseFloat(formValues.adultPax),
                    childnobedPax: parseFloat(formValues.childnobedPax),
                    childPax: parseFloat(formValues.childPax),
                    singlePax: parseFloat(formValues.singlePax),
                    price: parseFloat(formValues.price),
                    discount: parseFloat(formValues.discount),
                },
            });

            if (result.data) {
                refetchOrders();
                setEditModalState(false);
                setModalData({});
                resetForm();
                alert("Success");
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [editCommission] = useMutation(queries.EDIT_COMMISSION);

    const handleEditCommission = async () => {
        try {
            const formValues = getValuesCommissionForm();

            const totalAmountOfComm = modalData.members.filter(
                (item) => item.member._id === modalData.nested.member._id
            );

            const result = await editCommission({
                variables: {
                    memberId: modalData.nested._id,
                    commission: parseFloat(
                        formValues.commission / totalAmountOfComm.length
                    ),
                },
            });

            if (result.data) {
                alert("Success");
                refetchOrders();

                setModalData((prev) => ({
                    ...prev,
                    members: result.data.editCommission.members,
                    sponsorCommissions:
                        result.data.editCommission.sponsorCommissions,
                    leaderCommissions:
                        result.data.editCommission.leaderCommissions,
                }));

                setEditCommissionModal(false);
            }
        } catch (err) {
            alert("Failed");
            console.log(err);
        }
    };

    const [addMemberToOrder] = useMutation(queries.ADD_MEMBER_TO_ORDER);

    const handleAddMemberToOrder = async ({
        designation,
        name,
        dob,
        passport,
        phone,
        type,
        remark,
    }) => {
        try {
            const result = await addMemberToOrder({
                variables: {
                    orderId: modalData._id,
                    designation: designation.value,
                    name,
                    dob,
                    passport,
                    phone,
                    type: type.value,
                    remark,
                },
            });

            if (result.data) {
                resetForm();
                setValue("user", null);
                setModalData((prev) => ({
                    ...prev,
                    members: result.data.addMemberToOrder.members,
                }));
                refetchOrders();
                alert("Success");
            }
        } catch (err) {
            alert(err);
            console.log(err);
        }
    };

    const [uploadPayment] = useMutation(queries.UPLOAD_PAYMENT);

    const handleAddGallery = async () => {
        try {
            const formValues = getGalleryValues();

            let newImage = undefined;

            if (formValues.img.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.img[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.img[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "banners");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await uploadPayment({
                variables: {
                    packageId: modalData._id,
                    img: newImage ? newImage : "",
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetGalleryModal();
                refetchOrders();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
        dir: "desc",
        by: "_id",
    });

    // 1: Create state to hold data & pagination
    const [departureTableData, setDeparturesTableData] = useState([]);
    const [departureTablePaginationState, setDepartureTablePaginationState] =
        useState({
            limit: 10,
            page: 1,
        });

    const { data: projectsData, refetch: refetchProjects } = useQuery(
        queries.FETCH_PROJECTS,
        {
            fetchPolicy: "network-only",
            variables: {
                limit: 99999,
                page: 1,
                status: "all",
            },
            onCompleted: () => console.log(projectsData),
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        }
    );

    const {
        data: ordersData,
        refetch: refetchOrders,
        loading: toursLoading,
    } = useQuery(queries.FETCH_ORDERS, {
        fetchPolicy: "network-only",
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            by: paginationState.by ? paginationState.by : undefined,
            dir: paginationState.dir ? paginationState.dir : undefined,
            package: filters.package ? filters.package.value : undefined,
            date: filters.date,
            status: filters.status ? filters.status : undefined,
            unitNumber: filters.unitNumber,
            country: filters.country ? filters.country.value : undefined,
            state: filters.state ? filters.state.value : undefined,
        },
        onCompleted: ({ fetchOrders: { pagination } }) => {
            setPaginationState(pagination);
        },
    });

    useEffect(() => {
        if (editCommissionModal) {
            const { _id } = modalData;

            setValuesCommissionForm([
                { commission: modalData.nested.commission },
                { _id },
            ]);
        }
    }, [editCommissionModal, modalData, setValuesCommissionForm]);

    useEffect(() => {
        if (breakdownModalState) {
            const { groundPackage, supp, airticket, tipping, insurance, visa } =
                modalData.breakdown;

            setValuesBreakdownForm([
                { _id: modalData._id },
                { groundPackage },
                { supp },
                { airticket },
                { tipping },
                { insurance },
                { visa },
            ]);
        }
    }, [
        editCommissionModal,
        breakdownModalState,
        modalData,
        setValuesCommissionForm,
        setValuesBreakdownForm,
    ]);

    useEffect(() => {
        if (orderStatusModalState) {
            const { _id } = modalData;
            setValueStatusForm([
                {
                    status: {
                        label: _.startCase(modalData.status),
                        value: modalData.status,
                    },
                },
                { _id },
            ]);
        }
    }, [orderStatusModalState, modalData, setValueStatusForm]);

    useEffect(() => {
        if (editModalState) {
            const {
                _id,
                childPax,
                adultPax,
                childnobedPax,
                singlePax,
                status,
                price,
                discount,
            } = modalData;
            setValue([
                { _id },
                { childPax: childPax ? childPax : 0 },
                { adultPax: adultPax ? adultPax : 0 },
                { childnobedPax: childnobedPax ? childnobedPax : 0 },
                { singlePax: singlePax ? singlePax : 0 },
                { status: status ? status : "" },
                { price: price ? price : 0 },
                { discount: discount ? discount : 0 },
            ]);
        }
    }, [editModalState, modalData, setValue]);

    const resetCreateModal = () => {
        setCreateModalState(false);
        setModalData({});
        resetForm();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const resetCommissionModal = () => {
        setEditCommissionModal(false);
        resetCommissionForm();
    };

    const resetStatusModal = () => {
        setOrderStatusModalState(false);
        setModalData({});
        resetStatusForm();
    };

    const resetMemberModal = () => {
        setEditMemberState(false);
    };

    const resetBreakdownModal = () => {
        setBreakdownModalState(false);
        setModalData({});
        resetBreakdownForm();
    };

    const resetGalleryModal = () => {
        setGalleryModalState(false);
        resetGallery();
    };

    const columns = useMemo(
        () => [
            {
                Header: "OrderId",
                accessor: "orderId",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Proof of Payment",
                accessor: "receipt",
                Cell: ({ row: { original }, value }) => (
                    <div className="flex justify-center cursor-pointer">
                         <div
                            className="cursor-pointer mr-2"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });
                                setGalleryModalState(true);
                            }}
                        >
                            <FiPlus />
                        </div>
                        {value ? (
                            <>
                                <FiFile
                                    onClick={() => {
                                        window.open(value, "_blank"); // Open image in a new tab if clicked
                                    }}
                                ></FiFile>
                            </>
                        ) : (
                            <></>
                        )}
                       
                    </div>
                ),
            },
            {
                Header: "Action",
                id: "actions",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex justify-center wwwwwwwwitems-center">
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    refetchProjects();
                                    setEditModalState(true);
                                }}
                            >
                                <FiEdit />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    setOrderStatusModalState(true);
                                }}
                            >
                                <FaCheckCircle />
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Package",
                accessor: "package",
                Cell: ({ row }) => {
                    const value = row.original.tour
                        ? row.original.tour.package.name
                        : undefined;

                    return <div>{value ? value : ""}</div>;
                },
            },
            {
                Header: "Tour Date",
                accessor: "tourDate",
                Cell: ({ row }) => {
                    const value = row.original.tour
                        ? row.original.tour.tourDate
                        : undefined;

                    return (
                        <div>
                            {value
                                ? moment(parseInt(value)).format("YYYY-MM-DD")
                                : ""}
                        </div>
                    );
                },
            },
            {
                Header: "Price",
                accessor: "price",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> RM {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Members",
                id: "members",
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="flex justify-center cursor-pointer"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });

                                setEditMemberState(true);
                            }}
                        >
                            <FiUser />
                        </div>
                    );
                },
            },
            {
                Header: "Slots",
                accessor: "slots",
            },
            {
                Header: "Adult Pax",
                accessor: "adultPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Child No Bed Pax",
                accessor: "childnobedPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Child Pax",
                accessor: "childPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Single Pax",
                accessor: "singlePax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Order Date",
                accessor: "createdAt",
                Cell: ({ value }) => (
                    <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                ),
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                Cell: ({ value }) => {
                    if (value) {
                        return <div className="capitalize">{value.name}</div>;
                    }

                    return <div></div>;
                },
            },
        ],
        [setModalData, refetchProjects]
    );

    const [updateOrderStatus] = useMutation(queries.UPDATE_ORDER_STATUS);

    const [removeMemberFromOrder] = useMutation(
        queries.REMOVE_MEMBER_FROM_ORDER
    );

    const memberColumns = useMemo(
        () => [
            {
                Header: "Designation",
                accessor: "designation",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD")}
                    </div>
                ),
            },
            {
                Header: "Passport",
                accessor: "passport",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Phone",
                accessor: "phone",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Remark",
                accessor: "remark",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Delete",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center justify-center">
                            <div className="flex items-center">
                                <div
                                    className="ml-4 cursor-pointer"
                                    onClick={async () => {
                                        try {
                                            const result =
                                                await removeMemberFromOrder({
                                                    variables: {
                                                        orderId: modalData._id,
                                                        passport:
                                                            original.passport,
                                                    },
                                                });
                                            if (result.data) {
                                                alert("Success");
                                                refetchOrders();
                                                setModalData((prev) => ({
                                                    ...prev,
                                                    flights:
                                                        result.data
                                                            .removeMemberFromOrder
                                                            .members,
                                                }));
                                            }
                                        } catch (err) {
                                            alert("Failed");
                                            console.log(err);
                                        }
                                    }}
                                >
                                    <FiX color="red" />
                                </div>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [modalData, refetchOrders, removeMemberFromOrder]
    );

    const mobileMemberColumns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Passport",
                accessor: "passport",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Phone",
                accessor: "phone",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD")}
                    </div>
                ),
            },
            {
                Header: "Designation",
                accessor: "designation",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Remark",
                accessor: "remark",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Delete",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center justify-center">
                            <div className="flex items-center">
                                <div
                                    className="ml-4 cursor-pointer"
                                    onClick={async () => {
                                        try {
                                            const result =
                                                await removeMemberFromOrder({
                                                    variables: {
                                                        orderId: modalData._id,
                                                        passport:
                                                            original.passport,
                                                    },
                                                });
                                            if (result.data) {
                                                alert("Success");
                                                refetchOrders();
                                                setModalData((prev) => ({
                                                    ...prev,
                                                    flights:
                                                        result.data
                                                            .removeMemberFromOrder
                                                            .members,
                                                }));
                                            }
                                        } catch (err) {
                                            alert("Failed");
                                            console.log(err);
                                        }
                                    }}
                                >
                                    <FiX color="red" />
                                </div>
                            </div>
                        </div>
                    );
                },
            },
        ],
        [modalData, refetchOrders, removeMemberFromOrder]
    );

    const handleUpdateOrderStatus = async () => {
        const formValues = getValuesStatusForm();

        try {
            const result = await updateOrderStatus({
                variables: {
                    id: modalData._id,
                    status: formValues.status.value,
                },
                onError: ({ graphQLErrors, networkError }) => {
                    let errorMessage = "Failed to update order status.";
                    if (graphQLErrors) {
                        errorMessage += "\nGraphQL Errors:";
                        for (const graphQLError of graphQLErrors) {
                            errorMessage += `\n- ${graphQLError.message}`;
                        }
                    }
                    if (networkError) {
                        errorMessage += `\nNetwork Error: ${networkError}`;
                    }
                    alert(errorMessage);
                },
            });

            if (formValues.status.value === "completed" && result.data) {
                orderConfirmation(
                    result
                        ? result.data
                            ? result.data.updateOrderStatus
                            : null
                        : null
                );
            }

            alert("Success");
            refetchOrders();
            setOrderStatusModalState(false);
            setModalData({});
        } catch (error) {
            alert(error);
            console.error("Error updating order status:", error);
        }
    };

    const paginateData = (array, { page, limit }) => {
        let updatedArray;
        let currentIndex = (page - 1) * limit;

        updatedArray = [...array].slice(currentIndex, currentIndex + limit);

        return updatedArray;
    };

    const getAllCountries = () => {
        const CountryList = [
            Country.getCountryByCode("JP"),
            Country.getCountryByCode("KR"),
            Country.getCountryByCode("CN"),
            Country.getCountryByCode("TW"),
            Country.getCountryByCode("IN"),
            Country.getCountryByCode("TH"),
            Country.getCountryByCode("VN"),
        ];
        return CountryList.map((country) => ({
            value: country.isoCode, // Use isoCode as the value for the option
            label: country.name, // Use country name as the label for the option
        }));
    };

    const getStatesForCountry = (countryIsoCode) => {
        const states = State.getStatesOfCountry(countryIsoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
    };

    useEffect(() => {
        if (modalData.members && departureTablePaginationState.page) {
            const departures = modalData.members;
            setDeparturesTableData(
                paginateData(departures, departureTablePaginationState)
            );
        }
    }, [departureTablePaginationState, modalData.members]);

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions lg:flex justify-between items-start mb-4">
                <div className="w-full pr-4">
                    <div className="lg:grid grid-cols-3 col-gap-4 row-gap-2">
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="status"
                                placeholder={"Status"}
                                control={control}
                                options={statusOptions}
                                value={filters.status}
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val[0].value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={getAllCountries()}
                                value={filters.country}
                                placeholder="Country"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        country: val,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={
                                    filters.country
                                        ? getStatesForCountry(
                                              filters.country.value
                                          )
                                        : []
                                }
                                value={filters.state}
                                placeholder="State"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        state: val,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 mb-4 col-gap-4 row-gap-4">
                    {/* <div
                        className="text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-addgreen hover:text-white"
                        onClick={() => {
                            refetchProjects();
                            setCreateModalState(true);
                        }}
                    >
                        <IoIosAdd className="inline" /> New Order
                    </div> */}

                    <div
                        className="text-medium border-solid cursor-pointer border border-gray-400 mt-4 lg:mt-0 bg-white text-lynch text-sm p-2 min-w-32 flex justify-center items-center hover:bg-iconblue hover:text-white"
                        onClick={() => {
                            setFilters({
                                project: null,
                                status: null,
                                date: "",
                                unitNumber: "",
                            });
                        }}
                    >
                        <IoIosAdd className="inline" /> Reset Filter
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                data={ordersData ? ordersData.fetchOrders.data : []}
                updatePagination={setPaginationState}
                paginationState={paginationState}
                loading={toursLoading}
                pageCount={
                    ordersData
                        ? ordersData.fetchOrders.pagination.totalPages
                        : 0
                }
                totalCount={
                    ordersData ? ordersData.fetchOrders.pagination.documents : 0
                }
            />
            {editMemberState && (
                <Modal reset={resetMemberModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white p-6 rounded-md w-80vw lg:w-full">
                            <div className="flex justify-between items-end mb-4">
                                <form
                                    className="grid lg:grid-cols-2 col-gap-8 w-9/12 items-center mr-8"
                                    onSubmit={handleSubmit(
                                        handleAddMemberToOrder
                                    )}
                                >
                                    <div>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            Designation
                                        </div>
                                        <Controller
                                            as={
                                                <Select
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                />
                                            }
                                            name="designation"
                                            control={control}
                                            rules={{ required: true }}
                                            options={[
                                                {
                                                    label: "Miss",
                                                    value: "Miss",
                                                },
                                                {
                                                    label: "Mr",
                                                    value: "Mr",
                                                },
                                                {
                                                    label: "Ms",
                                                    value: "Ms",
                                                },
                                                {
                                                    label: "Mstr",
                                                    value: "Mstr",
                                                },
                                            ]}
                                        />
                                        {errors.designation && (
                                            <p className="text-red-400 text-xs">{`Designation cannot be empty`}</p>
                                        )}
                                    </div>
                                    {memberFields.map((item, i) => (
                                        <div
                                            className="w-full"
                                            key={`${i}-${item.name}`}
                                        >
                                            <div>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <input
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                        errors[item.name]
                                                            ? "border-red-400"
                                                            : "border-gray-300"
                                                    }`}
                                                    name={item.name}
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    placeholder={item.label}
                                                    type={
                                                        item.type
                                                            ? item.type
                                                            : "text"
                                                    }
                                                />
                                                {errors[item.name] && (
                                                    <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </form>
                                <div className="table-header-actions w-3/12">
                                    <div
                                        className="lg:block hidden text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 w-full text-center hover:bg-iconpink hover:text-white"
                                        onClick={handleSubmit(
                                            handleAddMemberToOrder
                                        )}
                                    >
                                        <IoIosAdd className="inline" /> Add
                                        Member
                                    </div>
                                </div>
                            </div>
                            <div className="text-lynch mb-4">Members</div>
                            {/* 5: Assign data & pagination to table, as well as calculate pageCount & totalCount using logic  */}
                            <Table
                                columns={
                                    window.innerWidth < 600
                                        ? mobileMemberColumns
                                        : memberColumns
                                }
                                data={departureTableData}
                                updatePagination={
                                    setDepartureTablePaginationState
                                }
                                paginationState={departureTablePaginationState}
                                pageCount={1}
                                totalCount={1}
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {editCommissionModal && (
                <Modal reset={resetCommissionModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Commission
                            </div>
                            <form
                                onSubmit={handleSubmitCommissionForm(
                                    handleEditCommission
                                )}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Member Commission
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errorsCommissionForm.commission
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="commission"
                                        ref={registerCommissionForm({
                                            required: true,
                                        })}
                                        placeholder="Commission"
                                        type="number"
                                    />
                                    {errorsCommissionForm.commission && (
                                        <p className="text-red-400 text-xs">
                                            Commission cannot be empty
                                        </p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Order
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditOrder)}
                                className="lg:w-104"
                            >
                                <div>
                                    {formFields.map((item, i) => (
                                        <div key={item.name}>
                                            <div className="mb-2 text-sm text-lynch font-medium">
                                                {item.label}
                                            </div>
                                            <input
                                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                    errors[item.name]
                                                        ? "border-red-400"
                                                        : "border-gray-300"
                                                }`}
                                                name={item.name}
                                                ref={register({
                                                    required: true,
                                                })}
                                                placeholder={item.label}
                                                type={
                                                    item.type
                                                        ? item.type
                                                        : "text"
                                                }
                                            />
                                            {errors[item.name] && (
                                                <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {orderStatusModalState && (
                <Modal reset={resetStatusModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Status
                            </div>
                            <form
                                onSubmit={handleSubmit(handleUpdateOrderStatus)}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Status
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={customStyles}
                                            isSearchable={false}
                                        />
                                    }
                                    name="status"
                                    control={statusFormControl}
                                    rules={{ required: true }}
                                    options={[
                                        {
                                            label: "Completed",
                                            value: "completed",
                                        },
                                        {
                                            label: "Cancelled",
                                            value: "cancelled",
                                        },
                                    ]}
                                />
                                {errors.status && (
                                    <p className="text-red-400 text-xs">{`Status cannot be empty`}</p>
                                )}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                New Order Form
                            </div>
                            <form
                                onSubmit={handleSubmit(handleCreateOrder)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                            type={
                                                item.type ? item.type : "text"
                                            }
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {breakdownModalState && (
                <Modal reset={resetBreakdownModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Price Breakdown
                            </div>
                            <form
                                onSubmit={handleBreakdownSubmit(
                                    handleUpdateBreakdown
                                )}
                                className="lg:w-104"
                            >
                                {breakdownList.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.name}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.value}
                                            ref={registerBreakdown({
                                                required: true,
                                            })}
                                            placeholder={item.name}
                                            type={"number"}
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}

                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {galleryModalState && (
                <Modal reset={resetGalleryModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Upload Gallery
                            </div>
                            <form
                                onSubmit={handleGallerySubmit(handleAddGallery)}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            galleryErrors.img
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="img"
                                        ref={galleryRegister()}
                                        type="file"
                                    />
                                    {galleryErrors.img && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default withUser(Orders);
