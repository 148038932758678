import React, { useState, useMemo, useEffect } from "react";
import queries from "../helpers/queries";
import { FiEdit, FiUnlock, FiPlus, FiX, FiFile } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import Table from "../Components/table";
import Modal from "../Components/modal";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import moment from "moment/moment";
import { Country, State } from "country-state-city";

const formFields = [
    {
        name: "name",
        label: "Package Name",
    },
    {
        name: "cnName",
        label: "Package Chinese Name",
    },
    {
        name: "assemblyTime",
        label: "Assembly Time",
        type: "datetime-local",
    },
    {
        name: "meetupPoint",
        label: "Meetup Point",
    },
    {
        name: "tourguideName",
        label: "Tourguide Name",
    },
    {
        name: "tourguideChineseName",
        label: "Tourguide Chinese Name",
    },
    {
        name: "tourguidePhone",
        label: "Tourguide Phone",
    },
    {
        name: "handcarryWeight",
        label: "Hand Carry Weight",
    },
    {
        name: "luggageWeight",
        label: "Luggage Weight",
    },
    {
        name: "deposit",
        label: "Deposit",
        type: "number",
    },
    {
        name: "savings",
        label: "Savings",
        type: "number",
    },
];

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const commissionFields = [
    {
        name: "coordinatorPercentage",
        label: "Coordinator's Percentage",
        type: "number",
    },
    {
        name: "preleaderPercentage",
        label: "Leader's Percentage",
        type: "number",
    },
    {
        name: "leaderPercentage",
        label: "Prime Leader's Percentage",
        type: "number",
    },
];

const statusOptions = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    },
];

const Packages = () => {
    let CountryList = [
        Country.getCountryByCode("JP"),
        Country.getCountryByCode("KR"),
        Country.getCountryByCode("CN"),
        Country.getCountryByCode("TW"),
        Country.getCountryByCode("ID"),
        Country.getCountryByCode("TH"),
        Country.getCountryByCode("VN"),
    ];
    const apollo = useApolloClient();

    const [modalData, setModalData] = useState(true);
    const [projectAttributeState, setProjectAttributeState] = useState(false);
    const [editModalState, setEditModalState] = useState(false);
    const [commissionModalState, setCommissionModalState] = useState(false);
    const [commissionStatusState, setCommissionStatusState] = useState(false);
    const [createModalState, setCreateModalState] = useState(false);
    const [galleryModalState, setGalleryModalState] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [states, setStates] = useState([]);

    const [filters, setFilters] = useState({
        state: undefined,
        country: undefined,
    });

    const {
        register,
        errors,
        handleSubmit,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            name: "",
            cnName: "",
            image: "",
            itinerary: "",
            video: "",
            assemblyTime: "",
            meetupPoint: "",
            tourguideName: "",
            tourguideChineseName: "",
            tourguidePhone: "",
            handcarryWeight: "7",
            luggageWeight: "20",
            deposit: "0",
            savings: "0",
            state: "",
            country: "",
        },
    });

    const {
        register: galleryRegister,
        errors: galleryErrors,
        handleSubmit: handleGallerySubmit,
        getValues: getGalleryValues,
        reset: resetGallery,
    } = useForm({
        defaultValues: {
            image: "",
        },
    });

    const {
        handleSubmit: handleCommissionSubmit,
        getValues: getValuesCommissionForm,
        setValue: setValuesCommissionForm,
        reset: resetCommissionStatusForm,
        control: commissionStatusFormControl,
    } = useForm({
        defaultValues: {
            status: undefined,
        },
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const customStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),
        }),
        [errors]
    );

    const {
        handleSubmit: handleProjectAttributeSubmit,
        getValues: getValuesProjectAttributes,
        register: registerProjectAttributes,
        reset: resetProjectAttributes,
    } = useForm({
        defaultValues: {
            attribute: "",
            value: "",
        },
    });

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };
    const [uploadGallery] = useMutation(queries.UPLOAD_GALLERY);
    const [createPackage] = useMutation(queries.CREATE_PROJECT);

    const handleCreatePackage = async () => {
        try {
            const formValues = getValues();

            let newImage = undefined;
            let newVideo = undefined;
            let newItinerary = undefined;

            if (formValues.image.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.image[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "longrich");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }
            if (formValues.itinerary.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.itinerary[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${imageresult.data.generateImageKitSignature.expire} - ${formValues.itinerary[0].name}`
                );
                imageFormData.append("folder", "longrich");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newItinerary = imageUploadResult.data.url;
            }
            if (formValues.video.length !== 0) {
                const videoResult = await generateSignature();
                const videoFormData = new FormData();

                videoFormData.append("file", formValues.video[0]); // Make sure "formValues.video" contains the video file
                videoFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                videoFormData.append(
                    "signature",
                    videoResult.data.generateImageKitSignature.signature
                );
                videoFormData.append(
                    "expire",
                    videoResult.data.generateImageKitSignature.expire
                );
                videoFormData.append(
                    "token",
                    videoResult.data.generateImageKitSignature.token
                );
                videoFormData.append(
                    "fileName",
                    `${formValues.video[0].name} - ${videoResult.data.generateImageKitSignature.expire}`
                );
                videoFormData.append("folder", "longrich");

                const videoUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    videoFormData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data", // Ensure correct Content-Type
                        },
                    }
                );

                newVideo = videoUploadResult.data.url;
            }

            const result = await createPackage({
                variables: {
                    name: formValues.name,
                    cnName: formValues.cnName,
                    image: newImage ? newImage : "",
                    itinerary: newItinerary ? newItinerary : "",
                    video: newVideo ? `${newVideo}/ik-video.mp4` : "",
                    assemblyTime: formValues.assemblyTime,
                    meetupPoint: formValues.meetupPoint,
                    tourguideName: formValues.tourguideName,
                    tourguideChineseName: formValues.tourguideChineseName,
                    tourguidePhone: formValues.tourguidePhone,
                    handcarryWeight: formValues.handcarryWeight,
                    luggageWeight: formValues.luggageWeight,
                    deposit: parseInt(formValues.deposit),
                    savings: parseInt(formValues.savings),
                    state: selectedState,
                    country: selectedCountry,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetCreateModal();
                refetchPackages();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert(err);
            console.log(err);
        }
    };

    const handleAddGallery = async () => {
        try {
            const formValues = getGalleryValues();

            let newImage = undefined;

            if (formValues.img.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.img[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.img[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "banners");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await uploadGallery({
                variables: {
                    packageId: modalData._id,
                    img: newImage ? newImage : "",
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    // Function to handle the change of selected country
    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value;
        setSelectedCountry(selectedCountry);

        const countryStates = State.getStatesOfCountry(selectedCountry);

        const payload =
        selectedCountry === "JP"
          ? countryStates.filter(
              (state) =>
                state.name === "Hokkaidō Prefecture" ||
                state.name === "Tokyo"
            )
          : selectedCountry === "VN"
          ? countryStates
              .filter(
                (location) =>
                  location.name === "Hà Nội" ||
                  location.name === "Hồ Chí Minh" ||
                  location.name === "Khánh Hòa" || // Nha Trang is in Khánh Hòa
                  location.name === "Đà Nẵng" ||
                  location.name === "Kiên Giang" // Phu Quoc is in Kiên Giang
              )
              .map((location) =>
                location.name === "Kiên Giang"
                  ? { ...location, name: "Phu Quoc" }
                  : location.name === "Khánh Hòa"
                  ? { ...location, name: "Nha Trang" }
                  : location
              )
          : selectedCountry === "ID"
          ? countryStates.filter((location) => location.name === "Bali")
          : selectedCountry === "KR"
          ? countryStates.filter(
              (location) =>
                location.name === "Seoul" ||
                location.name === "Busan" ||
                location.name === "Jeju"
            )
          : selectedCountry === "CN"
          ? countryStates
          : [];
      

        setStates(payload);
    };

    // Function to handle the change of selected state
    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);
    };

    const resetProjectAttributeModel = () => {
        setProjectAttributeState(false);
        setModalData({});
        resetProjectAttributes();
    };

    const resetCreateModal = () => {
        setCreateModalState(false);
        setModalData({});
        resetForm();
    };

    const resetGalleryModal = () => {
        setGalleryModalState(false);
        resetGallery();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const resetCommissionModal = () => {
        setCommissionModalState(false);
        setModalData({});
        resetForm();
    };

    const resetCommissionStatusModal = () => {
        setCommissionStatusState(false);
        setModalData({});
        resetCommissionStatusForm();
    };

    const [editCommission] = useMutation(queries.EDIT_PROJECT_COMMISSION);

    const handleEditCommission = async () => {
        try {
            const formValues = getValues();

            const commissionResult = await editCommission({
                variables: {
                    _id: modalData._id,
                    coordinatorPercentage: parseFloat(
                        formValues.coordinatorPercentage
                    ),
                    preleaderPercentage: parseFloat(
                        formValues.preleaderPercentage
                    ),
                    leaderPercentage: parseFloat(formValues.leaderPercentage),
                },
            });

            if (commissionResult.error) {
                alert("Failed");
            }

            if (commissionResult.data) {
                // do success stuff
                resetCommissionModal();
                refetchPackages();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const [editPackage] = useMutation(queries.EDIT_PROJECT);

    const handleEditPackage = async () => {
        try {
            const formValues = getValues();

            let updatedImage = undefined;
            let updatedItinerary = undefined;
            let updatedVideo = undefined;

            if (formValues.updatedImage.length > 0) {
                const imageresult = await generateSignature();

                const updateImageFormData = new FormData();

                updateImageFormData.append("file", formValues.updatedImage[0]);
                updateImageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                // ik signature expire token
                updateImageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                updateImageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                updateImageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                updateImageFormData.append(
                    "fileName",
                    `${formValues.updatedImage[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                updateImageFormData.append("folder", "packages");

                const updateImageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    updateImageFormData
                );

                updatedImage = updateImageUploadResult.data.url;
            }

            if (formValues.updatedItinerary) {
                if (formValues.updatedItinerary.length > 0) {
                    const imageresult = await generateSignature();

                    const updateImageFormData = new FormData();

                    updateImageFormData.append(
                        "file",
                        formValues.updatedItinerary[0]
                    );
                    updateImageFormData.append(
                        "publicKey",
                        "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                    );
                    // ik signature expire token
                    updateImageFormData.append(
                        "signature",
                        imageresult.data.generateImageKitSignature.signature
                    );
                    updateImageFormData.append(
                        "expire",
                        imageresult.data.generateImageKitSignature.expire
                    );
                    updateImageFormData.append(
                        "token",
                        imageresult.data.generateImageKitSignature.token
                    );
                    updateImageFormData.append(
                        "fileName",
                        `${formValues.updatedItinerary[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                    );
                    updateImageFormData.append("folder", "packages");

                    const updateImageUploadResult = await axios.post(
                        "https://upload.imagekit.io/api/v1/files/upload",
                        updateImageFormData
                    );

                    updatedItinerary = updateImageUploadResult.data.url;
                }
            }

            if (formValues.updatedVideo) {
                if (formValues.updatedVideo.length > 0) {
                    const imageresult = await generateSignature();

                    const updateImageFormData = new FormData();

                    updateImageFormData.append(
                        "file",
                        formValues.updatedVideo[0]
                    );
                    updateImageFormData.append(
                        "publicKey",
                        "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                    );
                    // ik signature expire token
                    updateImageFormData.append(
                        "signature",
                        imageresult.data.generateImageKitSignature.signature
                    );
                    updateImageFormData.append(
                        "expire",
                        imageresult.data.generateImageKitSignature.expire
                    );
                    updateImageFormData.append(
                        "token",
                        imageresult.data.generateImageKitSignature.token
                    );
                    updateImageFormData.append(
                        "fileName",
                        `${formValues.updatedVideo[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                    );
                    updateImageFormData.append("folder", "packages");

                    const updateImageUploadResult = await axios.post(
                        "https://upload.imagekit.io/api/v1/files/upload",
                        updateImageFormData
                    );

                    updatedVideo = updateImageUploadResult.data.url;
                }
            }

            const editResult = await editPackage({
                variables: {
                    _id: modalData._id,
                    name: formValues.name ? formValues.name : modalData.name,
                    cnName: formValues.cnName
                        ? formValues.cnName
                        : modalData.cnName,
                    image: updatedImage ? updatedImage : modalData.image,
                    itinerary: updatedItinerary
                        ? updatedItinerary
                        : modalData.itinerary,
                    video: updatedVideo ? updatedVideo : modalData.video,
                    assemblyTime: formValues.assemblyTime
                        ? formValues.assemblyTime
                        : modalData.assemblyTime,
                    meetupPoint: formValues.meetupPoint,
                    tourguideName: formValues.tourguideName,
                    tourguideChineseName: formValues.tourguideChineseName,
                    tourguidePhone: formValues.tourguidePhone,
                    handcarryWeight: formValues.handcarryWeight,
                    luggageWeight: formValues.luggageWeight,
                    deposit: parseFloat(formValues.deposit),
                    savings: parseFloat(formValues.savings),
                },
            });

            if (editResult.error) {
                alert("Failed");
            }

            if (editResult.data) {
                // do success stuff
                resetEditModal();
                refetchPackages();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const [removeAttributeFromProject] = useMutation(
        queries.REMOVE_ATTRIBUTE_FROM_PROJECT
    );

    const [updateCommissionStatus] = useMutation(
        queries.UPDATE_COMMISSION_STATUS
    );

    const handleUpdateCommissionStatus = async () => {
        const formValues = getValuesCommissionForm();

        await updateCommissionStatus({
            variables: {
                id: modalData._id,
                status: formValues.status.value,
            },
            onError: ({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    for (const graphQLError of graphQLErrors) {
                        console.log(graphQLError);
                    }
                }

                if (networkError) {
                    console.log(networkError);
                }
            },
        });

        alert("Success");
        refetchPackages();
        setCommissionStatusState(false);
        setModalData({});
    };

    const columns = useMemo(
        () => [
            {
                Header: "Thumbnail",
                accessor: "image",
                Cell: ({ value }) => (
                    <div className="flex justify-center">
                        <img className="max-w-8 max-h-8" src={value} alt={""} />
                    </div>
                ),
            },
            {
                Header: "Package Code",
                accessor: "packageCode",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Deposit",
                accessor: "deposit",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Assembly Time",
                accessor: "assemblyTime",
                Cell: ({ value }) => (
                    <div className="">
                        {moment(value).format("YYYY-MM-DD h:mm a")}
                    </div>
                ),
            },
            {
                Header: "Meetup Point",
                accessor: "meetupPoint",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Tourguide Name",
                accessor: "tourguideName",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Tourguide Chinese Name",
                accessor: "tourguideChineseName",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Tourguide Phone",
                accessor: "tourguidePhone",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Handcarry Weight",
                accessor: "handcarryWeight",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Luggage Weight",
                accessor: "luggageWeight",
                Cell: ({ value }) => <div className="">{value}</div>,
            },
            {
                Header: "Country",
                accessor: "country",
                Cell: ({ value }) => (
                    <div className="">{value ? value : ""}</div>
                ),
            },
            {
                Header: "State",
                accessor: "state",
                Cell: ({ value }) => (
                    <div className="">{value ? value : ""}</div>
                ),
            },
            {
                Header: "",
                id: "actions",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center">
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setEditModalState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiEdit />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    setCommissionStatusState(true);
                                }}
                            >
                                <FiUnlock />
                            </div>
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    setProjectAttributeState(true);
                                }}
                            >
                                <FiFile />
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={async () => {
                                    setModalData({
                                        ...original,
                                    });
                                    setGalleryModalState(true);
                                }}
                            >
                                <FiPlus />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const { data: packagesAllData } = useQuery(queries.FETCH_PROJECTS, {
        fetchPolicy: "network-only",
        variables: {
            limit: 9999,
            page: 1,
        },
        // onCompleted: ({ fetchPackages: { data } }) => setPackages(data),
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const {
        loading: packagesLoading,
        data: packagesData,
        refetch: refetchPackages,
    } = useQuery(queries.FETCH_PROJECTS, {
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            dir: "desc",
            by: "_id",
            state: filters.state ? filters.state.value : undefined,
            package: filters.package ? filters.package.value : undefined,
            salesDirector: filters.salesDirector
                ? filters.salesDirector.value
                : undefined,
            country: filters.country ? filters.country.value : undefined,
        },
        fetchPolicy: "network-only",
        onCompleted: ({ fetchPackages: { pagination } }) =>
            setPaginationState(pagination),
    });

    const [addProjectAttribute] = useMutation(queries.ADD_PROJECT_ATTRIBUTE);

    const handleAddProjectAttributes = async () => {
        try {
            const formValues = getValuesProjectAttributes();

            const addProjectAttributeResult = await addProjectAttribute({
                variables: {
                    packageId: modalData._id,
                    attribute: formValues.attribute,
                    value: formValues.value,
                },
            });

            if (addProjectAttributeResult.data) {
                setModalData((prev) => ({
                    ...prev,
                    attributes:
                        addProjectAttributeResult.data.addPackageAttribute
                            .attributes,
                }));
                alert("Success");
            } else {
                alert("Failed");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const ProjectAttributeColumns = useMemo(
        () => [
            {
                Header: "Title",
                accessor: "attribute",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Value",
                accessor: "value",
                Cell: ({ value }) => (
                    <textarea
                        className={`my-2 h-24 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs`}
                    >
                        {value}
                    </textarea>
                ),
            },
            {
                Header: "",
                id: "actions",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center justify-center">
                            <div
                                className="cursor-pointer"
                                onClick={async () => {
                                    try {
                                        const result =
                                            await removeAttributeFromProject({
                                                variables: {
                                                    packageId: modalData._id,
                                                    attributeId: original._id,
                                                },
                                            });
                                        if (result.data) {
                                            alert("Success");
                                            refetchPackages();
                                            setModalData((prev) => ({
                                                ...prev,
                                                attributes:
                                                    result.data
                                                        .removeAttributeFromPackage
                                                        .attributes,
                                            }));
                                        }
                                    } catch (err) {
                                        alert("Failed");
                                        console.log(err);
                                    }
                                }}
                            >
                                <FiX color="red" />
                            </div>
                        </div>
                    );
                },
            },
        ],
        [modalData, removeAttributeFromProject, refetchPackages]
    );

    useEffect(() => {
        if (editModalState) {
            const {
                _id,
                name,
                cnName,
                image,
                itinerary,
                video,
                assemblyTime,
                meetupPoint,
                tourguideName,
                tourguideChineseName,
                tourguidePhone,
                handcarryWeight,
                luggageWeight,
                deposit,
                savings,
                country,
                state,
            } = modalData;
            const formattedDeposit = parseFloat(deposit);
            const formattedSavings = parseFloat(savings);
            setValue([
                { _id },
                { name },
                { cnName },
                { image },
                { itinerary },
                { video },
                { assemblyTime },
                { meetupPoint },
                { tourguideName },
                { tourguideChineseName },
                { tourguidePhone },
                { handcarryWeight },
                { luggageWeight },
                { deposit: formattedDeposit },
                { savings: formattedSavings },
                { country },
                { state },
            ]);
        }
    }, [editModalState, modalData, setValue]);

    useEffect(() => {
        if (commissionModalState) {
            const {
                _id,
                coordinatorPercentage: coordinatorPercentagePreFormat,
                preleaderPercentage: preleaderPercentagePreFormat,
                leaderPercentage: leaderPercentagePreFormat,
            } = modalData;
            const coordinatorPercentage = NumberFormatter.format(
                coordinatorPercentagePreFormat
            );
            const preleaderPercentage = NumberFormatter.format(
                preleaderPercentagePreFormat
            );
            const leaderPercentage = NumberFormatter.format(
                leaderPercentagePreFormat
            );
            setValue([
                { _id },
                { coordinatorPercentage },
                { preleaderPercentage },
                { leaderPercentage },
            ]);
        }
    }, [commissionModalState, modalData, setValue]);

    useEffect(() => {
        if (commissionStatusState) {
            const { _id } = modalData;
            setValuesCommissionForm([
                {
                    status: {
                        label: _.startCase(modalData.status),
                        value: modalData.status,
                    },
                },
                { _id },
            ]);
        }
    }, [commissionStatusState, modalData, setValuesCommissionForm]);

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions lg:flex justify-end items-start">
                <div className="w-full pr-4 mb-4">
                    <div className="lg:grid grid-cols-3 col-gap-4 row-gap-2">
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                placeholder="Package"
                                isSearchable={true}
                                options={
                                    packagesAllData
                                        ? packagesAllData.fetchPackages.data.map(
                                              (pack) => ({
                                                  label: pack.name,
                                                  value: pack._id,
                                              })
                                          )
                                        : []
                                }
                                value={filters.package}
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        package: val,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="text-medium border-solid cursor-pointer border lg:w-2/12 w-6/12 mb-4 lg:mb-0 bg-white text-lynch text-sm p-2 hover:bg-addgreen hover:text-white"
                    onClick={() => setCreateModalState(true)}
                >
                    <IoIosAdd className="inline" /> New Package
                </div>
            </div>
            <Table
                columns={columns}
                data={packagesData ? packagesData.fetchPackages.data : []}
                updatePagination={setPaginationState}
                loading={packagesLoading}
                pageCount={
                    packagesData
                        ? packagesData.fetchPackages.pagination.totalPages
                        : 0
                }
                totalCount={
                    packagesData
                        ? packagesData.fetchPackages.pagination.documents
                        : 0
                }
            />
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Package
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditPackage)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: false })}
                                            placeholder={item.label}
                                            type={
                                                item.type ? item.type : "text"
                                            }
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}

                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    <div className="my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs">
                                        <img
                                            className="max-w-64 max-h-64"
                                            src={modalData.image}
                                            alt=""
                                        />
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.image
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="updatedImage"
                                        ref={register()}
                                        type="file"
                                    />
                                    {errors.image && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Update Itinerary
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.itinerary
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="updatedItinerary"
                                        ref={register()}
                                        type="file"
                                    />
                                    {errors.itinerary && (
                                        <p className="text-red-400 text-xs">{`Itinerary cannot be empty`}</p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Update Video
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.video
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="updatedVideo"
                                        ref={register()}
                                        type="file"
                                    />
                                    {errors.video && (
                                        <p className="text-red-400 text-xs">{`Video cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {commissionStatusState && (
                <Modal reset={resetCommissionStatusModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Status
                            </div>
                            <form
                                onSubmit={handleCommissionSubmit(
                                    handleUpdateCommissionStatus
                                )}
                                className="lg:w-104"
                            >
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Status
                                </div>
                                <Controller
                                    as={
                                        <Select
                                            styles={customStyles}
                                            isSearchable={false}
                                        />
                                    }
                                    name="status"
                                    control={commissionStatusFormControl}
                                    rules={{ required: true }}
                                    options={statusOptions}
                                />
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {commissionModalState && (
                <Modal reset={resetCommissionModal}>
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Commission Management
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditCommission)}
                                className="lg:w-104"
                            >
                                {commissionFields.map((item, i) => (
                                    <div key={item.name}>
                                        <div className="mb-2 text-sm text-lynch font-medium">
                                            {item.label}
                                        </div>
                                        <input
                                            className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                errors[item.name]
                                                    ? "border-red-400"
                                                    : "border-gray-300"
                                            }`}
                                            name={item.name}
                                            ref={register({ required: true })}
                                            placeholder={item.label}
                                            type={
                                                item.type ? item.type : "text"
                                            }
                                        />
                                        {errors[item.name] && (
                                            <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                        )}
                                    </div>
                                ))}
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                New Package
                            </div>
                            <form
                                onSubmit={handleSubmit(handleCreatePackage)}
                                className="lg:w-104"
                            >
                                {formFields.map((item, i) => {
                                    return (
                                        <div key={item.name}>
                                            <div className="mb-2 text-sm text-lynch font-medium">
                                                {item.label}
                                            </div>
                                            <input
                                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                    errors[item.name]
                                                        ? "border-red-400"
                                                        : "border-gray-300"
                                                }`}
                                                name={item.name}
                                                ref={register({
                                                    required: false,
                                                })}
                                                format-value="yyyy-MM-ddTHH:mm"
                                                placeholder={item.label}
                                                type={
                                                    item.type
                                                        ? item.type
                                                        : "text"
                                                }
                                            />
                                            {errors[item.name] && (
                                                <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                            )}
                                        </div>
                                    );
                                })}
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Country
                                </div>
                                <select
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs`}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select a Country</option>
                                    {CountryList.map((country, i) => (
                                        <option key={i} value={country.isoCode}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.country && (
                                    <p className="text-red-400 text-xs">{`Country cannot be empty`}</p>
                                )}
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    State
                                </div>
                                <select
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.country
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    value={selectedState}
                                    onChange={handleStateChange}
                                    name="state"
                                    ref={register({ required: false })}
                                >
                                    <option value="">Select a State</option>
                                    {states.map((state) => (
                                        <option
                                            key={state.isoCode}
                                            value={state.isoCode}
                                        >
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.state && (
                                    <p className="text-red-400 text-xs">{`State cannot be empty`}</p>
                                )}
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.image
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="image"
                                        ref={register()}
                                        type="file"
                                        key="image"
                                    />
                                    {errors.image && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Itinerary
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.itinerary
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="itinerary"
                                        ref={register()}
                                        type="file"
                                        key="itinerary"
                                    />
                                    {errors.itinerary && (
                                        <p className="text-red-400 text-xs">{`Itinerary cannot be empty`}</p>
                                    )}
                                </div>
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Video
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.video
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="video"
                                        ref={register()}
                                        type="file"
                                        key="video"
                                    />
                                    {errors.video && (
                                        <p className="text-red-400 text-xs">{`Video cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {galleryModalState && (
                <Modal reset={resetGalleryModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Upload Gallery
                            </div>
                            <form
                                onSubmit={handleGallerySubmit(handleAddGallery)}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            galleryErrors.img
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="img"
                                        ref={galleryRegister()}
                                        type="file"
                                    />
                                    {galleryErrors.img && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {projectAttributeState && (
                <Modal reset={resetProjectAttributeModel}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white p-6 rounded-md w-80vw lg:w-full">
                            <div className="mb-4 text-3xl font-bold">
                                Add Remarks
                            </div>
                            <div>
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Remark Title
                                </div>
                                <input
                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.name
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="attribute"
                                    ref={registerProjectAttributes({
                                        required: false,
                                    })}
                                    placeholder="Title"
                                />
                                {errors.attribute && (
                                    <p className="text-red-400 text-xs">{`Name cannot be empty`}</p>
                                )}
                            </div>
                            <div>
                                <div className="mb-2 text-sm text-lynch font-medium">
                                    Remark Content
                                </div>
                                <textarea
                                    className={`my-2 h-24 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                        errors.value
                                            ? "border-red-400"
                                            : "border-gray-300"
                                    }`}
                                    name="value"
                                    ref={registerProjectAttributes({
                                        required: false,
                                    })}
                                    placeholder="Content"
                                />
                            </div>
                            <div className="flex justify-center">
                                <div className="table-header-actions my-3">
                                    <div
                                        className="text-medium w-20vh border-solid cursor-pointer border border-gray-400 bg-addgreen text-white text-sm p-2 w-full text-center hover:bg-white hover:text-addgreen"
                                        onClick={handleProjectAttributeSubmit(
                                            handleAddProjectAttributes
                                        )}
                                    >
                                        Add Remarks
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 justify-between items-end mb-4"></div>
                            <Table
                                columns={ProjectAttributeColumns}
                                data={
                                    modalData.attributes
                                        ? modalData.attributes
                                        : []
                                }
                                // updatePagination={setMembersTablePaginationState}
                                // pageCount={users.pagination.totalPages}
                                // totalCount={users.pagination.documents}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default withUser(Packages);
