import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";

const orderConfirmation = async (original) => {
    const members = original.members;
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        const totalPages =
            original.members.length <= 10
                ? 1
                : Math.ceil((original.warranties.length - 10) / 10 + 1);

        for (let i = 0; i < totalPages; i++) {
            // Add a blank page to the document
            const page = pdfDoc.addPage();
            const fontSize = 20;

            // Get the width and height of the page
            const { height } = page.getSize();

            if (i === 0) {
                page.drawText(`LONGRICH TOUR & TRAVEL SDN. BHD.`, {
                    x: 60,
                    y: height - 2 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`B-1-01, Residensi The HERZ, Jalan 3/33a, `, {
                    x: 60,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Kepong Baru, 52100 Kuala Lumpur,`, {
                    x: 60,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `Tel: 03-6243 0272   Email: longrichtour@gmail.com`,
                    {
                        x: 60,
                        y: height - 5 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`GST ID: 202201032251`, {
                    x: 60,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Code:`, {
                    x: 370,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.orderId}`, {
                    x: 410,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date:`, {
                    x: 370,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${moment(parseInt(original.createdAt)).format("DD/MM/YYYY")}`, {
                    x: 410,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Page:`, {
                    x: 370,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${i + 1}`, {
                    x: 410,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`ORDER RECEIPT`, {
                    x: 245,
                    y: height - 8 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 8.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 8.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Contact Info`, {
                    x: 270,
                    y: height - 9.25 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 9.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 9.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Phone`, {
                    x: 70,
                    y: height - 10.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.phone}`, {
                    x: 400,
                    y: height - 10.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Email`, {
                    x: 70,
                    y: height - 11.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.email}`, {
                    x: 400,
                    y: height - 11.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 11.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 11.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 8.5 * fontSize,
                    },
                    end: {
                        x: 60,
                        y: height - 11.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 520,
                        y: height - 8.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 11.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                //flights
                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 13.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 13.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Flights`, {
                    x: 280,
                    y: height - 14.25 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 14.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 14.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });
// Assuming 'original.tour.flights' is an array with at least two elements
if (original.tour.flights && original.tour.flights.length > 0) {
    page.drawText(`${original.tour.flights[0].startCity || ""}`, {
        x: 70,
        y: height - 15.5 * fontSize,
        size: 10,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(`${original.tour.flights[0].endCity || ""}`, {
        x: 320,
        y: height - 15.5 * fontSize,
        size: 10,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(`${moment(parseInt(original.tour.flights[0].startTime || 0)).format("DD/MM/YYYY")} - ${original.tour.flights[0].code || ""}`, {
        x: 70,
        y: height - 16.25 * fontSize,
        size: 10,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
    });

    page.drawText(`${moment(parseInt(original.tour.flights[0].endTime || 0)).format("DD/MM/YYYY")} - ${original.tour.flights[0].code || ""}`, {
        x: 320,
        y: height - 16.25 * fontSize,
        size: 10,
        font: timesRomanFont,
        color: rgb(0, 0, 0),
    });

    if (original.tour.flights.length > 1) {
        page.drawText(`${original.tour.flights[1].startCity || ""}`, {
            x: 70,
            y: height - 17.5 * fontSize,
            size: 10,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.tour.flights[1].endCity || ""}`, {
            x: 320,
            y: height - 17.5 * fontSize,
            size: 10,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(parseInt(original.tour.flights[1].startTime || 0)).format("DD/MM/YYYY")} - ${original.tour.flights[1].code || ""}`, {
            x: 70,
            y: height - 18.25 * fontSize,
            size: 10,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(parseInt(original.tour.flights[1].endTime || 0)).format("DD/MM/YYYY")} - ${original.tour.flights[1].code || ""}`, {
            x: 320,
            y: height - 18.25 * fontSize,
            size: 10,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });
    }
}


                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 18.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 18.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 13.5 * fontSize,
                    },
                    end: {
                        x: 60,
                        y: height - 18.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 520,
                        y: height - 13.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 18.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                //Price
                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 20.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 20.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Price Summary`, {
                    x: 265,
                    y: height - 21.25 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 21.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 21.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText( `${original.slots} x Fare`, {
                    x: 70,
                    y: height - 22.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`RM ${NumberFormatter.format(original.price)}`, {
                    x: 400,
                    y: height - 22.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Discounts`, {
                    x: 70,
                    y: height - 23.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`RM 0.00`, {
                    x: 400,
                    y: height - 23.25 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 23.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 23.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Grand Total`, {
                    x: 70,
                    y: height - 24.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`RM ${NumberFormatter.format(original.price)}`, {
                    x: 400,
                    y: height - 24.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 25 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 20.5 * fontSize,
                    },
                    end: {
                        x: 60,
                        y: height - 25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 520,
                        y: height - 20.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });
    
                //
                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 26.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 26.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Members`, {
                    x: 280,
                    y: height - 27.5 * fontSize,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 28 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 28 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                members.forEach((member, index) => {
                    page.drawText( `${member.name}`, {
                        x: 70,
                        y: height - (28.75 + parseInt(index)) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                members.forEach((member, index) => {
                    page.drawText( `${member.passport}`, {
                        x: 400,
                        y: height - (28.75 + parseInt(index)) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - (29.5 + parseInt(original.members.length - 1)) * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - (29.5 + parseInt(original.members.length - 1)) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - (26.75) * fontSize,
                    },
                    end: {
                        x: 60,
                        y: height - (29.5 + parseInt(original.members.length - 1)) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 520,
                        y: height - (26.75) * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - (29.5 + parseInt(original.members.length - 1)) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

            } else {
                
            }
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(pdfBytes, `orderconfirmation-${original.orderId}`, "application/pdf");
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default orderConfirmation;
