import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Table from "../Components/table";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import moment
  from "moment";

const Dashboard = () => {

  const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const { data: toursData, loading: toursLoading } = useQuery(
    queries.FETCH_CASES,
    {
      fetchPolicy: "network-only",
      variables: {
        limit: 5,
        page: 1,
        dir: "desc",
        by: "_id",
      },
    }
  );



  const columns = useMemo(
    () => [
        {
            Header: "Code",
            accessor: "tourCode",
            Cell: ({ value }) => {
                if (value) {
                    return <div> {value}</div>;
                }

                return <div></div>;
            },
        },
        {
            Header: "Package",
            accessor: "package",
            Cell: ({ value }) => {
                if (value) {
                    return <div> {value.name}</div>;
                }

                return <div></div>;
            },
        },
        {
            Header: "Adult Price",
            accessor: "adultPrice",
            Cell: ({ value }) => (
                <div>RM {NumberFormatter.format(value)}</div>
            ),
        },
        {
            Header: "Child No Bed Price",
            accessor: "childNoBedPrice",
            Cell: ({ value }) => (
                <div>RM {NumberFormatter.format(value)}</div>
            ),
        },
        {
            Header: "Single Price",
            accessor: "singlePrice",
            Cell: ({ value }) => (
                <div>RM {NumberFormatter.format(value)}</div>
            ),
        },
        {
            Header: "Child Price",
            accessor: "childPrice",
            Cell: ({ value }) => (
                <div>RM {NumberFormatter.format(value)}</div>
            ),
        },
        {
          Header: "Tour Date",
          accessor: "tourDate",
          Cell: ({ value }) => (
              <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
          ),
      },
        {
            Header: "Created At",
            accessor: "createdAt",
            Cell: ({ value }) => (
                <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
            ),
        },
        {
            Header: "Created By",
            accessor: "createdBy",
            Cell: ({ value }) => {
                if (value) {
                    return <div className="capitalize">{value.name}</div>;
                }

                return <div></div>;
            },
        },

    ],
    [NumberFormatter
    ]
);

  return (
    <section className="p-12">
      <div className="grid grid-cols-2 col-gap-6 mb-6">
      </div>
      <div>
        <div className="bg-white p-4">
          <div className="text-lynch font-bold text-sm uppertour">
            Recent Tours
          </div>
          <div className="text-green-600 font-bold text-xs">Latest 5 Tours</div>
        </div>
        <Table
          columns={columns}
          data={toursData ? toursData.fetchTours.data : []}
          loading={toursLoading}
          hidePagination
        />
        <div className="bg-white p-4 border-t text-sm text-lightpurple">
          <div>
            <Link
              className="transition duration-500 ease-in-out hover:bg-specialblue p-4"
              to="/tours"
            >
              See all tours
            </Link>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
