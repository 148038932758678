import React, { useState, useMemo, useEffect } from "react";
import queries from "../helpers/queries";
import { IoIosAdd } from "react-icons/io";
import { useForm } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Table from "../Components/table";
import Modal from "../Components/modal";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FiEdit, FiX } from "react-icons/fi";

const Airport = () => {
    const [modalData, setModalData] = useState(true);
    const [airportsData, setAirportsData] = useState([]);
    const [editModalState, setEditModalState] = useState(false);
    const [createModalState, setCreateModalState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);

    const {
        register,
        errors,
        handleSubmit,
        getValues,
        setValue,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            name: "",
            img: "",
        },
    });

    const [deleteAirport] = useMutation(queries.DELETE_AIRPORT);
    const [createAirport] = useMutation(queries.CREATE_AIRPORT);

    const handleCreateAirport = async () => {
        try {
            const formValues = getValues();

            const result = await createAirport({
                variables: {
                    label: formValues.label,
                    value: formValues.value,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetCreateModal();
                window.location.reload();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    useEffect(() => {
        if (editModalState) {
            const { _id, value, label } = modalData;
            setValue([{ _id }, { value }, {label}]);
        }
    }, [editModalState, modalData, setValue]);

    const resetCreateModal = () => {
        setCreateModalState(false);
        setModalData({});
        resetForm();
    };

    const resetEditModal = () => {
        setEditModalState(false);
        setModalData({});
        resetForm();
    };

    const [editAirport] = useMutation(queries.EDIT_AIRPORT);

    const handleEditAirport = async () => {
        try {
            const formValues = getValues();

            const editResult = await editAirport({
                variables: {
                    _id: modalData._id,
                    label: formValues.label,
                    value: formValues.value,
                },
            });

            if (editResult.error) {
                alert("Failed");
            }

            if (editResult.data) {
                // do success stuff
                resetEditModal();
                window.location.reload();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "Label",
                accessor: "label",
            },
            {
                Header: "Value",
                accessor: "value",
            },
            {
                Header: "",
                id: "actions",
                Cell: ({ row: { original } }) => {
                    return (
                        <div className="flex items-center">
                            <div
                                className="cursor-pointer mr-2"
                                onClick={async () => {
                                    setEditModalState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiEdit />
                            </div>

                            <div
                                className="ml-4 cursor-pointer"
                                onClick={() => {
                                    setDeleteModalState(true);
                                    setModalData({
                                        ...original,
                                    });
                                }}
                            >
                                <FiX />
                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const {
        loading: airportLoading,
        data,
    } = useQuery(queries.FETCH_AIRPORTS, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirportsData(data.fetchAirports),
    });

    return (
        <div className="py-4 px-12">
            <div className="table-header-actions lg:flex justify-end">
                <div
                    className="text-medium border-solid cursor-pointer border border-gray-400 bg-white text-lynch text-sm p-2 mb-5 hover:bg-addgreen hover:text-white"
                    onClick={() => setCreateModalState(true)}
                >
                    <IoIosAdd className="inline" /> New Airport
                </div>
            </div>
            <Table
                columns={columns}
                data={airportsData ? airportsData : []}
                loading={airportLoading}
            />
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                New Airport
                            </div>
                            <form
                                onSubmit={handleSubmit(handleCreateAirport)}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Label
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.label
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="label"
                                        ref={register({ required: false })}
                                        placeholder="Label"
                                    />
                                    {errors.label && (
                                        <p className="text-red-400 text-xs">{`Label cannot be empty`}</p>
                                    )}
                                </div>
                                <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Value
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.name
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="value"
                                        ref={register({ required: false })}
                                        placeholder="Value"
                                    />
                                    {errors.name && (
                                        <p className="text-red-400 text-xs">{`Value cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {editModalState && (
                <Modal reset={resetEditModal}>
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                                          <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Edit Airport
                            </div>
                            <form
                                onSubmit={handleSubmit(handleEditAirport)}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Label
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.label
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="label"
                                        ref={register({ required: true })}
                                        placeholder="Label"
                                    />
                                    {errors.label && (
                                        <p className="text-red-400 text-xs">{`Label cannot be empty`}</p>
                                    )}
                                     <div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Value
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors.name
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="value"
                                        ref={register({ required: false })}
                                        placeholder="Value"
                                    />
                                    {errors.name && (
                                        <p className="text-red-400 text-xs">{`Value cannot be empty`}</p>
                                    )}
                                </div>
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
            {deleteModalState && (
                <Modal
                    reset={() => {
                        setModalData({});
                        setDeleteModalState(false);
                    }}
                >
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white m-12 rounded-md">
                            <div className="mb-6 text-2xl font-bold text-center">
                                Are You Sure?
                            </div>
                            <div className="flex justify-between items-center text-center">
                                <div
                                    className="border-solid border-black border w-full mr-6 p-2 cursor-pointer text-icongreen bg-specialgreen hover:bg-icongreen hover:text-white"
                                    onClick={async () => {
                                        try {
                                            const result = await deleteAirport({
                                                variables: {
                                                    _id: modalData._id,
                                                },
                                            });
                                            if (result.data) {
                                                window.location.reload();
                                                setDeleteModalState(false);
                                            }
                                        } catch (err) {
                                            alert("Failed");
                                            console.log(err);
                                        }
                                    }}
                                >
                                    Yes
                                </div>
                                <div
                                    className="border-solid border-black border w-full mr-6 p-2 cursor-pointer text-iconpink bg-specialpink hover:bg-iconpink hover:text-white"
                                    onClick={() => setDeleteModalState(false)}
                                >
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default withUser(Airport);
